<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="main-home-div">
    <headerMenu></headerMenu>
    <router-view />
    <mainFooterVue></mainFooterVue>
  </div>
</template>

<script>
import headerMenu from '@/components/Header/headerMenu'
import mainFooterVue from './components/footer/mainFooter.vue'

export default {
  components: {
    headerMenu,
    mainFooterVue
  }
}
</script>

<style lang="scss">
.main-home-div {
  max-width: 100vw;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
