<template>
  <div class="about">
    <h1>ثبت آدرس جدید</h1>
    <div class="contact-row">
      <div class="contact-img mob-hide">
        <img src="@/assets/img/icons/project.png" alt="ContactImage" />
      </div>
      <div class="contact-card mob-w-40">
        <el-card>
          <el-form :model="form" label-width="120px">
            <el-form-item class="lbl-info" label="عنوان آدرس">
              <el-input
                class="input-info"
                v-model="formAddress.title"
                maxlength="150"
                show-word-limit
              />
            </el-form-item>
            <el-form-item class="lbl-info" label="کشور">
              <div class="dv-info-flx">
                <div class="input-info-flx">
                  <el-select
                    class="input-info"
                    v-model="formAddress.country"
                    placeholder="کشور مورد نظر را انتخاب نمایید"
                  >
                    <el-option
                      v-for="(item_type, key) in savedCountries"
                      :key="key"
                      :label="item_type.title"
                      :value="item_type.value"
                    />
                  </el-select>
                </div>
                <div class="btn-info-flx">
                  <el-button
                    type="primary"
                    @click="showNewCategory"
                    class="btn-send"
                    circle
                    disabled
                  >
                    <el-icon>
                      <Plus />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="lbl-info" label="شهر">
              <div class="dv-info-flx">
                <div class="input-info-flx">
                  <el-select
                    class="input-info-flx"
                    v-model="formAddress.city"
                    placeholder="شهر خود را انتخاب نمایید"
                  >
                    <el-option
                      v-for="(item_type, key) in savedCities"
                      :key="key"
                      :label="item_type.title"
                      :value="item_type.value"
                    />
                  </el-select>
                </div>
                <div class="btn-info-flx">
                  <el-button
                    type="primary"
                    @click="showNewType()"
                    class="btn-send"
                    circle
                    disabled
                  >
                    <el-icon>
                      <Plus />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="lbl-info" label="خیابان اصلی">
              <el-input
                class="input-info"
                v-model="formAddress.street1"
                maxlength="250"
                show-word-limit
              />
            </el-form-item>
            <el-form-item class="lbl-info" label="خیابان فرعی">
              <el-input
                class="input-info"
                v-model="formAddress.street2"
                maxlength="250"
                show-word-limit
              />
            </el-form-item>

            <el-form-item class="lbl-info" label="کوچه">
              <el-input
                class="input-info"
                v-model="formAddress.alley"
                maxlength="250"
                show-word-limit
              />
            </el-form-item>

            <el-form-item class="lbl-info" label="اطلاعات اضافی">
              <el-input
                class="input-info"
                v-model="formAddress.extra"
                maxlength="250"
                show-word-limit
              />
            </el-form-item>

            <el-form-item class="lbl-info" label="کد پستی">
              <el-input
                class="input-info"
                v-model="formAddress.postal"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>

            <el-form-item class="lbl-info" label="شماره تلفن ثابت">
              <el-input
                class="input-info"
                v-model="formAddress.telephone"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
            <!-- <el-form-item label="Activity time">
              <el-col :span="11">
                <el-date-picker v-model="form.date1" type="date" placeholder="Pick a date" style="width: 100%" />
              </el-col>
              <el-col :span="2" class="text-center">
                <span class="text-gray-500">-</span>
              </el-col>
              <el-col :span="11">
                <el-time-picker v-model="form.date2" placeholder="Pick a time" style="width: 100%" />
              </el-col>
            </el-form-item> -->
            <!-- <el-form-item class="lbl-info" label="عضویت در خبر نامه">
              <el-switch class="input-info" v-model="form.delivery" />
            </el-form-item> -->
            <!-- <el-form-item label="Activity type">
              <el-checkbox-group v-model="form.type">
                <el-checkbox label="Online activities" name="type" />
                <el-checkbox label="Promotion activities" name="type" />
                <el-checkbox label="Offline activities" name="type" />
                <el-checkbox label="Simple brand exposure" name="type" />
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="Resources">
              <el-radio-group v-model="form.resource">
                <el-radio label="Sponsor" />
                <el-radio label="Venue" />
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item class="lbl-info" label="متن پیغام : ">
              <el-input  class="input-info" v-model="form.desc" type="textarea" />
            </el-form-item> -->
            <hr />
            <el-form-item>
              <el-button
                v-if="editMode"
                type="primary"
                @click="editSavedProject"
                class="btn-send"
                >اصلاح</el-button
              >
              <el-button
                v-else
                type="primary"
                @click="saveNewAddress"
                class="btn-send"
                >ثبت</el-button
              >
              <el-button class="btn-back" @click="gotoSavedAddresses"
                >بازگشت</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <el-dialog
      v-model="dialogNewCategoryType"
      :title="dialogTitle"
      width="600"
      :before-close="handleClose"
      modal-class="dialog-new"
    >
      <el-form :model="form" label-width="120px">
        <el-form-item class="lbl-info" label="عنوان">
          <el-input
            class="input-info"
            v-model="NewItem.title"
            maxlength="250"
            show-word-limit
          />
        </el-form-item>
        <el-form-item class="lbl-info" label="کد آیتم">
          <el-input
            class="input-info"
            v-model="NewItem.value"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <!-- <el-form-item class="lbl-info" label="توضیحات">
          <el-input
            class="input-info"
            v-model="NewItem.description"
            maxlength="1500"
            show-word-limit
            type="textarea"
          />
        </el-form-item> -->
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="dialog-btn-back"
            @click="dialogNewCategoryType = false"
            >بازگشت</el-button
          >
          <el-button
            class="dialog-btn-save"
            type="primary"
            @click="saveNewIetm"
          >
            ذخیره
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import axios from "axios";
import { Plus, Delete } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

// import { UploadInstance, UploadRawFile } from 'element-plus'
export default {
  components: { ElNotification, ElMessage, ElMessageBox },
  created() {
    debugger;
    if (this.$route.params.AdNo != undefined) {
      this.getSavedAddressInfo();
    }
  },
  data() {
    return {
      img_url: process.env.VUE_APP_IMG_URL,
      savedImages: [
        // {
        //   file_name: "1_1",
        // },
        // {
        //   file_name: "2_2",
        // },
      ],
      editMode: false,
      dialogTitle: "ثبت دسته بندی جدید",
      NewItem: {
        title: "",
        value: "",
        description: "",
      },
      dialogModeCategory: true,
      dialogNewCategoryType: false,
      selectedImage: [],
      fileListUploading: [],
      fileList: [
        // {
        //   name: "food.jpeg",
        //   url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        // },
        // {
        //   name: "plant-1.png",
        //   url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        // },
        // {
        //   name: "food.jpeg",
        //   url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        // },
      ],
      dialogImageUrl: "",
      dialogVisible: false,
      savedCountries: [
        {
          title: "ایران",
          value: "IR",
        },
      ],
      savedCities: [
        {
          title: "تهران",
          value: "TH",
        },
      ],
      savedImagesTotal: "",

      formAddress: {
        title: "آدرس پیش فرض",
        country: "IR",
        city: "TH",
        street1: "",
        street2: "",
        alley: "",
        telephone: "",
        postal: "",
      },
    };
  },
  methods: {
    getSavedAddressInfo() {
      debugger;
      this.editMode = true;
      this.$postApi({
        url: "Address/GetSingleAddressById?idSent=" + this.$route.params.AdNo,
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          // let category_temp = this.savedCountries.filter(
          //   (x) =>
          //     x.value.toLowerCase() == res.allData[0].category.toLowerCase()
          // )[0];
          // let type_temp = this.savedCities.filter(
          //   (x) => x.value.toLowerCase() == res.allData[0].type.toLowerCase()
          // )[0];
          debugger;
          this.formAddress = {
            title: res.allData[0].addressTitle,
            country: res.allData[0].addressCountry,
            city: res.allData[0].addressCity,
            street1:res.allData[0].addressStreat1,
            street2:res.allData[0].addressStreat2,
            alley: res.allData[0].addressAlley,
            extra: res.allData[0].addressExtra,
            telephone: res.allData[0].addressTelephone,
            postal: res.allData[0].addressPostalNo,
          };
       
          debugger;
        
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    saveNewIetm() {
      debugger;
      let dataHolder = {
        title: this.NewItem.title,
        value: this.NewItem.value,
        description: this.NewItem.description,
      };
      if (this.dialogModeCategory == true) {
        this.$postApi({
          url: "Category/AddNewCategory",
          data: dataHolder,
          needToken: true,
        })
          .then((res) => {
            debugger;
            this.dialogNewCategoryType = false;
            ElNotification({
              title: "دسته بندی جدید",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>دسته بندی جدید  " +
                this.NewItem.title +
                " با موفقیت ثبت گردید.</p>",
              type: "success",
            });
            this.getSavedCategories();
          })
          .catch((err) => {
            debugger;
            console.log(err);
          });
      } else {
        this.$postApi({
          url: "Types/AddNewType",
          data: dataHolder,
          needToken: true,
        })
          .then((res) => {
            debugger;
            this.dialogNewCategoryType = false;
            ElNotification({
              title: "نوع جدید",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>نوع جدید  " +
                this.NewItem.title +
                " با موفقیت ثبت گردید.</p>",
              type: "success",
            });
            this.getSavedTypes();
          })
          .catch((err) => {
            debugger;
            console.log(err);
          });
      }
    },
    showNewType() {
      return;
      this.dialogTitle = "ثبت نوع جدید";
      this.dialogModeCategory = false;
      this.dialogNewCategoryType = true;
    },
    showNewCategory() {
      return;
      this.dialogTitle = "ثبت دسته بندی جدید";
      this.dialogModeCategory = true;
      this.dialogNewCategoryType = true;
    },

    editSavedProject() {
      debugger;
      let bTokken = localStorage.getItem("uToken");
      if (bTokken == null) {
        ElNotification({
          title: "احراز هویت ناقض",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>خطا : برای ثبت پروژه باید دوباره وارد حساب کاربری خود شوید" +
            "</p>",
          type: "error",
        });
        return;
      }

      // let countryId = this.savedCountries.filter(
      //   (x) => x.value == this.formAddress.country
      // )[0].id;
      // let cityId = this.savedCities.filter(
      //   (x) => x.value == this.formAddress.city
      // )[0].id;

      // this command
      // formDataHolder.append("fileImages", this.fileListUploading);
      let dataHolder = {
        Id : this.$route.params.AdNo,
        userId: this.$myState.userLoggedID,
        addressTitle: this.formAddress.title,
        addressCountry: this.formAddress.country,
        addressCity: this.formAddress.city,
        addressStreat1: this.formAddress.street1,
        addressStreat2: this.formAddress.street2,
        addressAlley: this.formAddress.alley,
        addressExtra: this.formAddress.extra,
        addressPostalNo: this.formAddress.postal,
        addressTelephone: this.formAddress.telephone,
      };
      this.$postApi({
        url: "Address/EditSavedAddress",
        data: dataHolder,
        needToken: true,
      })
        .then((res) => {
          debugger;
          ElNotification({
            title: "اصلاح اطلاعات آدرس",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>اطلاعات آدرس : " +
              this.formAddress.title +
              " با موفقیت اصلاح شد." +
              "</p>",
            type: "success",
          });
          this.gotoSavedAddresses();
        })
        .catch((err) => {
          debugger;
          ElNotification({
            title: "اصلاح آدرس",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>خطا :  " +
              err.response.data.status +
              "</p>",
            type: "error",
          });
          console.log(err);
        });
    },
    saveNewAddress() {
      debugger;

      let bTokken = localStorage.getItem("uToken");
      if (bTokken == null) {
        ElNotification({
          title: "احراز هویت ناقض",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>خطا : برای ثبت پروژه باید دوباره وارد حساب کاربری خود شوید" +
            "</p>",
          type: "error",
        });
        return;
      }

      // let countryId = this.savedCountries.filter(
      //   (x) => x.value == this.formAddress.country
      // )[0].id;
      // let cityId = this.savedCities.filter(
      //   (x) => x.value == this.formAddress.city
      // )[0].id;

      // this command
      // formDataHolder.append("fileImages", this.fileListUploading);
      let dataHolder = {
        userId: this.$myState.userLoggedID,
        addressTitle: this.formAddress.title,
        addressCountry: this.formAddress.country,
        addressCity: this.formAddress.city,
        addressStreat1: this.formAddress.street1,
        addressStreat2: this.formAddress.street2,
        addressAlley: this.formAddress.alley,
        addressExtra: this.formAddress.extra,
        addressPostalNo: this.formAddress.postal,
        addressTelephone: this.formAddress.telephone,
      };
      this.$postApi({
        url: "Address/AddNewAddress",
        data: dataHolder,
        needToken: true,
      })
        .then((res) => {
          debugger;
          ElNotification({
            title: "ثبت اطلاعات آدرس",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>اطلاعات آدرس : " +
              this.formAddress.title +
              " با موفقیت ثبت شد." +
              "</p>",
            type: "success",
          });
          this.gotoSavedAddresses();
        })
        .catch((err) => {
          debugger;
          ElNotification({
            title: "ثبت آدرس جدید",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>خطا :  " +
              err.response.data.status +
              "</p>",
            type: "error",
          });
          console.log(err);
        });

      // this.$postApi({
      //   url: "Projects/AddNewProject",
      //   data: formDataHolder,
      //   upload: true,
      // })
      //   .then((res) => {
      //     debugger;
      //   })
      //   .catch((err) => {
      //     debugger;
      //     console.log(err);
      //   });
    },
    gotoSavedAddresses() {
      this.$router.push({ name: "SavedAddresses" });
    },
  },
  setup() {},
};
</script>

<style lang="scss">
.img-carousel-div {
  position: relative;

  .img-carousel-delete {
    position: absolute;
    right: 1px;
    top: 1px;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    width: 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.img-carousel-wrapper {
  width: 100%;
}

.img-carousel {
  background: rgba(116, 147, 144, 0.605);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.dialog-btn-back {
  margin-right: 5px;
}

.dialog-btn-save {
}

.dialog-new {
  direction: rtl;
}

.pic-input {
  border: 1px solid #cccccc7b;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 94%;
}

.el-form-item__label {
  padding-left: 10px !important;
  align-items: flex-start !important;
}

.lbl-info {
  margin-left: 5px;
  margin-right: 5px;
}

.dv-info-flx {
  width: 100% !important;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.input-info-flx {
  width: 99% !important;
  margin-left: 5px;
  margin-right: 0px;
}

.btn-info-flx {
  width: 7% !important;
  margin-left: 5px;
  margin-right: 5px;
}

.input-info {
  margin-left: 5px;
  margin-right: 5px;
}

.btn-send {
  margin-left: 10px;
  margin-right: 10px;
}

.btn-back {
}

.contact-row {
  width: 85%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.contact-img {
  width: 20%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.contact-card {
  width: 40%;
  direction: rtl;
}
</style>
