<template>
    <div class="search-wrapper">
        <div class="search-div">

            <div class="input-text">
                <el-input v-model="searchInput" class="w-50 m-2 input-search" clearable placeholder="جستجوی پروژه های موجود"
                    @input="handleSearch" />
            </div>
            <div class="input-icon">
                <!-- <el-icon>
                <Search />
            </el-icon> -->
                <!-- <el-button :icon="Search" circle /> -->
                <el-button type="default" circle>
                    <img src="@/assets/img/icons/search.png" alt="Find" />
                </el-button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchInput: '',
        }
    },
    methods: {
        handleSearch() {
            debugger
            // this.$emit('searchChanged', this.searchInput);

            this.$emit('NewSearch',this.searchInput);

            // let holderSearchResult = this.$myState.cardItemsBase.filter(x => x.title.includes(this.searchInput)
            //     || x.body.includes(this.searchInput));
            // this.$myState.cardItemsFiltered = holderSearchResult;
        },
    }
}
</script>

<style lang="scss" scoped>
.search-wrapper {

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.search-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .input-icon {
        width: 2%;
        min-width: 50px;
        margin-top: 0px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .input-text {
        width: 70%;
    }

    .input-search {
        width: 99%;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
</style>