<template>
    <el-carousel :interval="0" type="default" :height="slideHeight" class="slide-wrapper">
        <el-carousel-item v-for="slide in images_saved" :key="slide.name" height="auto">
            <div class="slide-img">
                <img class="img-main" :src="slide.path" :alt="slide.name" />
                <!-- <h3 text="2xl" justify="center"> {{ slide.name }}</h3> -->
            </div>
        </el-carousel-item>
    </el-carousel>
    <!-- <Carousel :autoplay="2000" :wrap-around="true" :itemsToShow="1" :transition="500">
        <Slide v-for="slide in images_saved" :key="slide">
            <div class="carousel__item">
                {{ slide.name }}
                <img :src="slide.path" />
            </div>
        </Slide>

        <template #addons>
            <Pagination />
        </template>
</Carousel> -->
</template>

<script>
import { defineComponent } from 'vue'
// import { Carousel, Pagination, Slide } from 'vue3-carousel'

// import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
    name: 'Autoplay',
    components: {
        // Carousel,
        // Slide,
        // Pagination,
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        onResize() {
            // console.log(`resized 1 : ${this.windowWidth}`);
            this.windowWidth = window.innerWidth;
            // console.log(`resized 2 : ${this.windowWidth}`);
            if (this.windowWidth > 900) {
                this.slideHeight = "450px";
            }
            else if (this.windowWidth < 900 && this.windowWidth > 400) {
                this.slideHeight = "200px";
            }
            else {
                this.slideHeight = "150px";
            }

        }
    },
    data() {
        return {
            slideHeight: "450px",
            windowWidth: window.innerWidth,
            images_saved: [
                {
                    name: 'image no 1',
                    path: require('@/assets/img/1.jpg')
                },
                {
                    name: 'image no 2',
                    path: require('@/assets/img/2.jpg')
                },
                {
                    name: 'image no 3',
                    path: require('@/assets/img/3.jpg')
                }
            ],
        }
    }
})
</script>
<style lang="scss" scoped>
.slide-wrapper {
    background: rgba(49, 79, 110, 0.495);
}

.slide-img {
    width: 100% !important;
    height: auto !important;

    .img-main {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
    }

    // img {
    //     width: 100% !important;
    //     height: 100% !important;
    //     object-fit: cover !important;

    // }
}

.el-carousel__item h3 {
    color: #475669;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

// .carousel__item {
//     width: 100%;
//     height: 400px;

//     img {
//         object-fit: cover;
//         width: 100%;
//         height: 100%;
//     }
// }

// .carousel__slide {
//     padding: 5px;
// }

// .carousel__viewport {
//     perspective: 2000px;
// }

// .carousel__track {
//     transform-style: preserve-3d;
// }

// .carousel__slide--sliding {
//     transition: 0.5s;
// }

// .carousel__slide {
//     opacity: 0.9;
//     transform: rotateY(-20deg) scale(0.9);
// }

// .carousel__slide--active~.carousel__slide {
//     transform: rotateY(20deg) scale(0.9);
// }

// .carousel__slide--prev {
//     opacity: 1;
//     transform: rotateY(-10deg) scale(0.95);
// }

// .carousel__slide--next {
//     opacity: 1;
//     transform: rotateY(10deg) scale(0.95);
// }

// .carousel__slide--active {
//     opacity: 1;
//     transform: rotateY(0) scale(1.1);
// }</style>