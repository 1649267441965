<template>
  <div class="card-holder">
    <el-card class="box-card" v-for="(item_card, key) in $myState.cardItemsFiltered" :key="key">
      <template #header>
        <div class="card-header">
          <el-tooltip :content="item_card.date_create" placement="top" effect="dark">
            <span class="item-category">
              <el-tag class="mx-1" type="warning" effect="dark">{{
      item_card.p_title
    }}</el-tag></span>
          </el-tooltip>
          <el-badge :value="item_card.category" class="item-badge">
            <el-button>{{ item_card.type }}</el-button>
          </el-badge>
        </div>
      </template>
      <div class="card-img" @click="gotoDetails(item_card)">
        <!-- <img :src="require(`@/assets/img/pack/application.png`)" /> -->
        <img :src="imgBaseUrl + item_card.fileNames.split('~')[0]" :alt="item_card.fileNames[0]" @error="setErrImage" />
      </div>
      <el-tooltip :content="item_card.p_body" placement="top" effect="dark">
        <p class="project-body">{{ item_card.p_body }}</p>
      </el-tooltip>

      <template #footer>
        <el-divider content-position="left" border-style="dashed">
          <div class="buy-btn-row" v-if="item_card.p_price != 0">
            <div v-if="$myState.savedBasketItems.filter((x) => x.id == item_card.id)
      .length > 0
      ">
              <el-badge :value="$myState.savedBasketItems.filter(
      (x) => x.id == item_card.id
    )[0].count
      " class="item">
                <el-button type="primary" class="button btn-buy" @click="gotoBasketView()">تکمیل خرید</el-button>
              </el-badge>
            </div>
            <div v-else>
              <el-button type="danger" class="button btn-buy" @click="addThisItemToBasket(item_card)">اضافه به سبد
                خرید</el-button>
            </div>

            <el-button type="primary" class="button btn-buy" @click="gotoDetails(item_card)" text>توضیحات
              بیشتر</el-button>
          </div>
          <div class="buy-btn-row" v-else>
            <div>
              <el-button type="success" class="button btn-buy" @click="gotoDetails(item_card)">دانلود رایگان
              </el-button>
            </div>

            <el-button type="primary" class="button btn-buy" @click="gotoDetails(item_card)" text>توضیحات
              بیشتر</el-button>
          </div>
        </el-divider>
      </template>
    </el-card>

    <div class="pagintaion-wrapper">
      <el-pagination background v-model="startPage" layout="prev, pager, next" :page-size="9" :pager-count="11"
        :total="allProjectCount" @change="onPageChanged" />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/mixin";
import { ElNotification } from "element-plus";

export default {
  components: { ElNotification },
  mixins: [mixin],
  created() {
    this.GetAllSavedProjectCount();
    this.getProjects(this.searchValue);
  },
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_IMG_URL_RAW,
      startPage: 1,
      seekPage: 9,
      searchValue: "0",
      allProjectCount: 0,
    };
  },
  methods: {
    setErrImage(e) {
      console.log("error image : ", e);
      e.target.src = require("@/assets/img/pack/application.png");
    },
    gotoBasketView() {
      this.$router.push({ name: "BasketView" });
    },
    GetAllSavedProjectCount() {
      this.$postApi({
        url: "Projects/GetAllProjectCount",
        data: null,
        needToken: false,
      })
        .then((res) => {
          debugger;
          this.allProjectCount = res.allData;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },

    onPageChanged(item_page) {
      debugger;
      this.startPage = item_page;
      this.getProjects(this.searchValue);
    },
    getProjects(searchValueSent) {
      this.searchValue = searchValueSent;
      let dataHolder = { start: this.startPage };
      this.$postApi({
        url:
          "Projects/GetProjectByPage?start=" +
          this.startPage +
          "&seek=" +
          this.seekPage +
          "&search=" +
          searchValueSent,
        data: null,
        needToken: false,
      })
        .then((res) => {
          this.isLoading = false;
          debugger;
          if (res.allData.length > 0) {
            this.$myState.cardItemsBase = res.allData;
            this.$myState.cardItemsFiltered = this.$myState.cardItemsBase;
            // this.$myState.cardItemsFiltered = res.allData;
          } else {
            ElNotification({
              title: "خطا در بازیابی اطلاعات پروژه ها",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>خطا در بازیابی اطلاعات پروژه ها ، لطفا دوباره تلاش نمایید.</p>",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    gotoDetails(item_card) {
      debugger;
      if (item_card.p_price == 0) {
        this.$router.push({
          name: "ItemDetailsFree",
          params: { ItemNo: item_card.id },
        });
      }
      else {
        this.$router.push({
          name: "ItemDetails",
          params: { ItemNo: item_card.id },
        });
      }

    },
    addThisItemToBasket(item_card) {
      let dataHolder = {
        id: item_card.id,
        title: item_card.p_title,
        body: item_card.p_body,
        category: item_card.category,
        price: item_card.p_price,
        dateTime: "1401/02/18 18:25",
        count: 1,
      };
      this.addToBasket(dataHolder);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-divider__text.is-left {
  left: -10px;
}

.btn-buy {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-6px);
  }
}

.customized-tooltip {
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.project-body {
  width: 95%;
  margin: 0 auto;
  text-align: right;
  direction: rtl;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  transition: transform 0.3s ease-in-out;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}

.buy-btn-row {
  display: flex;
}

.pagintaion-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item-badge {
    direction: ltr;
    margin-right: 40px;
  }
}

.item-category {
  direction: ltr;
}

.card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.box-card {
  width: 30%;
  min-width: 300px;
  direction: rtl;
  max-height: 550px;
  margin: 9px;

  .card-img {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.1) rotate(5deg);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
