<template>
  <div class="home">
    <div class="news-title">
      {{ Newstitle }}
    </div>
    <!-- <sliderCarousel></sliderCarousel> -->
    <!-- <el-row class="mb-4">
      <el-button>پیش فرض</el-button>
      <el-button type="primary">Primary</el-button>
      <el-button type="success">Success</el-button>
      <el-button type="info">Info</el-button>
      <el-button type="warning">Warning</el-button>
      <el-button type="danger">Danger</el-button>
    </el-row> -->
    <!-- <MainHomeTabVue></MainHomeTabVue> -->
    <!-- <mainSearch @NewSearch="NewSearchOccured"></mainSearch> -->
    <div class="main-body">
      <div class="body-items">
        <singleCardNews ref="mainNewsCards"></singleCardNews>
      </div>
      <div class="body-side">
        <!-- <mainCategory></mainCategory>
        <filterSearch></filterSearch> -->
        <div class="news-title-side">
          اخبار متفرقه
        </div>
        <hr>
        <div class="news-img">
          <img src="@/assets/img/icons/news.png" alt="News" />
        </div>
        <hr>ّ
        <div class="news-title-side">
          در حال حاضر خبری یافت نشد
        </div>
      </div>
    </div>

    <!-- <simpleCarousel></simpleCarousel> -->

  </div>
</template>

<script>
import singleCardNews from '@/components/Cards/singleCardNews.vue'
// import simpleCarousel from '@/components/Carousel/simpleCarousel.vue'
import sliderCarousel from '@/components/Carousel/sliderCarousel.vue'
import mainSearch from '@/components/search/mainSearch.vue'
import mainCategory from '@/components/catagories/mainCategory.vue'
// import MainHomeTabVue from '@/components/Tabs/MainHomeTab.vue'
import filterSearch from '@/components/catagories/filterSearch.vue'

// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    singleCardNews,
    // simpleCarousel,
    sliderCarousel,
    mainSearch,
    mainCategory,
    filterSearch,
    // MainHomeTabVue
  },
  data() {
    return {
      Newstitle: 'اخبار',
    }
  },
  methods: {
    NewSearchOccured(search_value_sent) {
      debugger
      if (this.$refs.mainNewsCards != undefined) {
        this.$refs.mainNewsCards.getProjects(search_value_sent);
      }
    }
  }
}
</script>
<style scoped lang="scss">
.news-title {
  margin: 10px;
  text-shadow: -2px 4px 7px rgba(5, 169, 174, 0.95);
}

.news-title-side {
  margin: 10px;
  text-shadow: 8px -2px 21px rgba(24, 20, 48, 0.95);
}


.main-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .body-items {
    width: 79%;
  }

  .body-side {
    width: 19%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin-bottom: 25px;
    min-width: 210px;

    .news-img {
      width: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width:600px) {

  .body-items {
    width: 99% !important;
  }

  .body-side {
    width: 99% !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin-bottom: 25px;
    min-width: 210px;
  }
}
</style>
