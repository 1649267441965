<template>
  <div class="main-basket">
    <!-- <el-steps class="basket-steps" :space="200" align-center :active="0" finish-status="success">
      <el-step title="نمایش آیتم های سبد خرید" />
      <el-step title="دریافت پیش فاکتور شما" />
      <el-step title="پرداخت و دریافت" />
    </el-steps> -->

    <div class="header-projects">
      <el-card>
        پیغام های ثبت شده
      </el-card>
    </div>

    <div>
      <div class="demo-collapse">
        <div class="brief-wrapper">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>منو عملیات</span>
                <div class="header-img">
                  <img src="@/assets/img/icons/list.png" />
                </div>
              </div>
            </template>
            <div class="box-basket-brief">
              <div class="brief-row-projects">
                پیغام های ثبت شده امکان اصلاح ندارند
                <!-- <div class="brief-right">پیغام های ثبت شده امکان اصلاح ندارند</div>
                <div class="brief-left">
                  {{ $myState.countBasketItems }}
                </div> -->
              </div>
              <div class="brief-row-projects">
                <div class="brief-right">ارسال ایمیل</div>
                <div class="brief-left">
                  <el-button style="margin-left: 5px;" size="small" type="success"
                    @click="handleDeletePre(scope.$index, scope.row)"><el-icon>
                      <Message />
                    </el-icon>ایمیل</el-button>
                </div>
              </div>
              <el-divider border-style="dashed" />
              <div class="footer-total-price">
                <el-button type="info" class="btn-total-price">
                  تعداد پیفام ها : {{ savedProjects.length }}
                </el-button>
              </div>
            </div>
            <template #footer>
              <div class="footer-total-price">
                <el-button type="success" class="btn-total-price" disabled @click="gotoNewProject">
                  ثبت پیغام جدید
                </el-button>
              </div>
            </template>
          </el-card>
        </div>
        <div class="table-wrapper" id="element-to-convert">
          <el-card>
            <el-table v-if="savedProjects.length > 0" class="basket-table" :data="savedProjects" stripe
              style="width: 100%" row-class-name="basket-row">
              <!-- <el-table-column label="عنوان" width="180">
                <template #default="scope" style="
                    background: red !important;
                    display: flex;
                    justify-content: flex-end;
                    text-align: right;
                  ">
                  {{ scope.row.p_title }}
                </template>
              </el-table-column> -->
              <el-table-column class="basket-col" prop="username" label="مشخصات کاربری" width="150" />
              <el-table-column class="basket-col" prop="userTelephone" label="تلفن" width="220" />
              <el-table-column class="basket-col" prop="userEmail" label="ایمیل" width="120" />
              <el-table-column class="basket-col" prop="checkNews" label="خبرنامه" width="120" />
              <el-table-column class="basket-col" prop="description" label="توضیحات" width="120" />
              <el-table-column class="basket-col" prop="dateSaved" label="زمان ثبت" />
              <el-table-column label="عملیات" width="280">
                <template #default="scope" style="display: flex">
                  <div style="display: flex;">
                    <el-button style="margin-left: 5px;" size="small" type="danger"
                      @click="handleDeletePre(scope.$index, scope.row)"><el-icon>
                        <Delete />
                      </el-icon></el-button>
                    <el-button size="small" type="warning" @click="handleEdit(scope.$index, scope.row)"
                      disabled><el-icon>
                        <EditPen />
                      </el-icon></el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-else>
              <img src="@/assets/img/icons/box.png" alt="پروژه ای ثبت نشده است" />
              پیغامی ای ثبت نشده است
            </div>
          </el-card>
          <vue3-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
            :paginate-elements-by-height="1400" filename="hee hee" :pdf-quality="2" :manual-pagination="false"
            pdf-format="a4" pdf-orientation="landscape" pdf-content-width="1000px" @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)" ref="html2Pdf">
            <template v-slot:pdf-content>
              <!-- PDF Content Here -->

              <el-card>
                <el-table class="basket-table" :data="savedProjects" stripe style="width: 100%"
                  row-class-name="basket-row">
                  <!-- <el-table-column label="عنوان" width="180">
                    <template style="
                        background: red !important;
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                      ">
                      {{ scope.row.p_title }}
                    </template>
    </el-table-column> -->
                  <el-table-column class="basket-col" prop="username" label="مشخصات کاربری" width="150" />
                  <el-table-column class="basket-col" prop="userTelephone" label="تلفن" width="220" />
                  <el-table-column class="basket-col" prop="userEmail" label="ایمیل" width="120" />
                  <el-table-column class="basket-col" prop="checkNews" label="خبرنامه" width="120" />
                  <el-table-column class="basket-col" prop="description" label="توضیحات" width="120" />
                  <el-table-column class="basket-col" prop="dateSaved" label="زمان ثبت" />
                  <!-- <el-table-column label="عملیات" width="280">
                    <template #default="scope" style="display: flex">
                      <div style="
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                        ">
                        <div style="width: 20%; margin-left: 10px">
                          <el-input v-model="scope.row.count" type="number" min="1" max="99" placeholder="تعداد" />
                        </div>
                        <div style="width: 15%;min-width: 100px;display: flex;">
                          <el-button style="margin-left: 5px;" size="small" type="danger"
                            @click="handleDeletePre(scope.$index, scope.row)"><el-icon>
                              <Delete />
                            </el-icon></el-button>
                          <el-button size="small" type="warning" @click="handleEdit(scope.$index, scope.row)"><el-icon>
                              <EditPen />
                            </el-icon></el-button>
                        </div>
                      </div>
                    </template>
                  </el-table-column> -->
                </el-table>
              </el-card>
            </template>
          </vue3-html2pdf>
        </div>
        <div class="export-div">
          <el-card>
            <el-button @click="generateReport" type="info" class="btn-total-price">
              دانلود پیغام های ثبت شده
            </el-button>
            <!-- <el-button @click="exportToPDF" type="info" class="btn-total-price">
               پیش فاکتور
            </el-button> -->
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import mixin from '@/mixins/mixin';
import msgBox from '@/mixins/msgBox'
import axios from 'axios'
import { ElMessage, ElMessageBox } from "element-plus";


export default {
  mixins: [mixin, msgBox],
  components: { Vue3Html2pdf, ElMessage, ElMessageBox },
  data() {
    return {
      savedProjects: [],
      activeName: "1",
      tabPosition: "right",
      basketTable: [],
    };
  },
  computed: {},
  created() {
    this.getSavedContacts();
    this.basketTable = this.$myState.savedBasketItems;
  },
  methods: {
    getSavedContacts() {
      debugger
      // let bTokken = localStorage.getItem("uToken");
      // const config = {
      //   headers: { Authorization: `Bearer ${bTokken}` }
      // };
      // axios.post("Projects/getSavedContacts", null,config)
      //   .then((res) => {
      //     debugger;
      //   })
      //   .catch((err) => {
      //     debugger;
      //     console.log(err);
      //   });
      this.$postApi({
        url: "Contact/GetAllContacts",
        data: null,
        needToken: true
      })
        .then((res) => {
          debugger;
          this.savedProjects = res.allData;
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    gotoNewProject() {
      this.$router.push({ name: 'ProjectDetails' })
      // this.showSimpleAlert('سبد شما خالی است', 'لطفا در صورت نیاز ابتدا آیتم های خود را انتخاب و دوباره تلاش نمایید');
    },
    onProgress($event) {

    },
    startPagination() { },
    hasPaginated() { },
    beforeDownload($event) { },
    hasDownloaded($event) { },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    handleEdit(index_sent, rowData) {
      debugger
      this.$router.push({ name: 'ProjectDetails', params: { PrId: rowData.id } })
    },
    handleDeletePre(index_sent, rowData) {
      debugger
      ElMessageBox.confirm(
        "آیا از حذف آیتم : " + rowData.username + " مطمئن می باشید ؟",
        "حذف",
        {
          confirmButtonText: "حذف",
          cancelButtonText: "بازگشت",
          type: "warning",
        }
      )
        .then((ans) => {
          debugger
          this.$postApi({
            url: "Contact/DeleteSavedContact?idSent=" + rowData.id,
            data: null,
            needToken: true
          })
            .then((res) => {
              debugger;
              this.getSavedContacts();
              ElMessage({
                type: "حذف",
                message: "پیغام مورد نظر با موفقیت حذف گردید",
              });
            })
            .catch((err) => {
              debugger;
              console.log(err);
            });

        })
        .catch(() => {
          //   ElMessage({
          //     type: "info",
          //     message: "خطا در حذف آیتم",
          //   });
        });
    },
    // debugger;
    // this.$myState.savedBasketItems = this.$myState.savedBasketItems.filter(
    //   (x) => x.id != rowData.id
    // );
  },
};
</script>

<style lang="scss">
.header-projects {
  margin: 10px;
}

.header-img {
  width: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.export-div {
  width: 100%;
}

.box-basket-brief {
  width: 100%;
  direction: rtl;
  text-align: right;

  .brief-row-projects {
    width: 100%;
    position: relative;
    display: flex;

    .brief-right {
      width: 45%;
      right: 3px;
    }

    .brief-left {
      width: 45%;
      left: 3px;
    }
  }
}

.footer-total-price {
  width: 80%;
  margin: 0 auto;

  .btn-total-price {
    width: 100%;
  }
}

// .el-table .el-table__cell {
//     padding: 8px 0;
//     min-width: 0;
//     box-sizing: border-box;
//     text-overflow: ellipsis;
//     vertical-align: middle;
//     position: relative;
//     text-align: right !important;
//     z-index: var(--el-table-index);
// }

.el-input .ops-div {
  display: flex;
}

.el-input .input-div {
  width: 60%;
}

.el-input .delete-div {
  width: 30%;
}

.el-table .basket-row {
  // --el-table-tr-bg-color: rgb(149, 45, 45);
  text-align: center !important;
}

.demo-collapse {
  display: flex;
  flex-wrap: wrap;

  .el-table thead {
    text-align: right !important;

    .el-table tr {
      text-align: right !important;
      direction: rtl;
    }
  }

  .el-table th.el-table__cell {
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--el-table-header-bg-color);
    text-align: right;
    direction: rtl;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    text-align: right !important;
    direction: rtl;
  }

  .brief-wrapper {
    width: 25%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .basket-brif {}

  .table-wrapper {
    width: 70%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;

    .basket-table {
      direction: rtl;
    }
  }

  .el-collapse-item__header {
    width: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    height: var(--el-collapse-header-height);
    line-height: var(--el-collapse-header-height);
    background-color: var(--el-collapse-header-bg-color);
    color: var(--el-collapse-header-text-color);
    cursor: pointer;
    border-bottom: 1px solid var(--el-collapse-border-color);
    font-size: var(--el-collapse-header-font-size);
    font-weight: 500;
    transition: border-bottom-color var(--el-transition-duration);
    outline: 0;
    direction: rtl;
    flex-direction: column-reverse;
  }
}

.main-basket {
  width: 100%;

  .basket-steps {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
