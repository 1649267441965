<template>
    <div class="signup-main">
        <el-card :body-style="{ padding: '0px' }" class="signup-div">
            <div class="singup-img">
                <img src="@/assets/img/icons/sign-up.png" class="image" />
            </div>

            <div class="login-wrapper" style="padding: 14px">
                <el-card class="box-card">
                    <span>لطفا اطلاعات خود را در کادر های زیر وارد نمایید</span>
                    <el-row class="row-info" :gutter="20">
                        <el-input v-model="userName" class="w-50" placeholder="رضا" :prefix-icon="Calendar">
                            <template #append>
                                نام
                                <el-icon class="icn">
                                    <List />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-row>
                    <el-row class="row-info" :gutter="20">
                        <el-input v-model="userFamily" class="w-50" placeholder="اکبری" :prefix-icon="Calendar">
                            <template #append>
                                نام خانوادگی
                                <el-icon class="icn">
                                    <List />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-row>
                    <el-row class="row-info" :gutter="20">
                        <el-input v-model="userEmail" class="w-50" placeholder="reza_123@gmail.com" :prefix-icon="Calendar">
                            <template #append>
                                آدرس ایمیل
                                <el-icon class="icn">
                                    <Message />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-row>
                    <el-row class="row-info" :gutter="20">
                        <el-input v-model="userMobile" class="w-50" placeholder="0912345678" :prefix-icon="Calendar">
                            <template #append>
                                کاربری-شماره موبایل
                                <el-icon class="icn">
                                    <Iphone />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-row>
                    <el-row class="row-pwd" :gutter="20">
                        <el-input v-model="userPwd" type="password" placeholder="1234" show-password
                            class="pwd-input w-50 m-2" :suffix-icon="Calendar">
                            <template #append>
                                رمز عبور
                                <el-icon class="icn">
                                    <Lock />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-row>
                    <!-- <el-input v-model="userInfo" placeholder="نام کاربری" clearable class="user-input" />
                <el-input v-model="userPwd" type="password" placeholder="رمز عبور" show-password class="pwd-input" /> -->
                    <div class="btns">
                        <el-button type="primary" class="btn-enter" @click="makeNewRegistration">ثبت نام</el-button>
                        <el-button type="success" class="btn-back">بازگشت</el-button>
                    </div>
                    <el-link @click="gotoLogin">
                        قبلا ثبت نام کرده اید؟ ورود به سایت<el-icon class="el-icon--right"><icon-view /></el-icon>
                    </el-link>
                </el-card>

            </div>
        </el-card>
    </div>
</template>

<script>
import { ElNotification } from 'element-plus'


export default {
    components: { ElNotification },
    data() {
        return {
            userName: '',
            userFamily: '',
            userEmail: '',
            userMobile: '',
            userPwd: '',
        }
    },
    methods: {
        makeNewRegistration() {
            let dataHolder = {

                name: this.userName,
                family: this.userFamily,
                username: this.userMobile,
                email: this.userEmail,
                password: this.userPwd
            }

            this.$postApi({ url: 'Authenticate/register', data: dataHolder })
                .then(res => {
                    debugger
                    if (res.allData.status == 'Success') {
                        ElNotification({
                            title: 'عملیات موفقیت آمیز',
                            dangerouslyUseHTMLString: true,
                            message: "<p style='direction:rtl;'>اطلاعات حساب کاربری  " + this.userMobile + " با موفقیت در سامانه ثبت گردید.</p>",
                            type: "success",
                        });
                        this.$router.push({ name: 'login' });
                    }
                    else {
                        ElNotification({
                            title: 'خطا در عملیات',
                            dangerouslyUseHTMLString: true,
                            message: "<p style='direction:rtl;'>خطای  " + res.allData.message + " در زمان عملیات ثبت نام.</p>",
                            type: "success",
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                })

        },
        gotoLogin() {
            this.$router.push({ name: 'login' });
        }
    }
}
</script>

<style lang="scss" scoped>
.el-input-group {
    direction: ltr;
}

.signup-main {
    width: 100%;
    position: relative;
}

.signup-div {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    .singup-img {

        width: 150px;
        margin: 0 auto;
        margin-top: 10px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
}

.login-wrapper {
    width: 100%;
    margin: 0 auto;
    //direction: rtl;

    .box-card {
        width: 100%;
    }

    .icn {
        margin-left: 5px;
    }

    .row-user {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .row-info {
        margin-bottom: 10px;
    }

    .row-pwd {
        width: 106%;
        margin-left: -10px !important;
    }

    .pwd-input {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%;
    }

    .btn-enter {
        margin: 5px;
    }


    .btn-back {
        margin: 5px;
    }
}
</style>