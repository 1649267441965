import { ElNotification } from "element-plus";

export default {
  components: { ElNotification },
  methods: {
    addToBasket(newItem) {
      // {
      //     id: 101,
      //     title: "بسته آموزشی",
      //     body: "آموزش زبان برنامه نویسی c#",
      //     category: "C#",
      //     price:100,
      //     dateTime: "1401/02/18 18:25",
      //     count:1,
      //   },
      debugger;
      if (
        this.$myState.savedBasketItems.filter((x) => x.id == newItem.id)
          .length > 0
      ) {
        this.$myState.savedBasketItems.filter((x) => x.id == newItem.id)[0]
          .count++;
      } else {
        newItem.count = 1;
        this.$myState.savedBasketItems.push(newItem);
      }

      this.$myState.totalBasketAmount = 0;
      this.$myState.savedBasketItems.forEach((item_basket) => {
        this.$myState.totalBasketAmount +=
          item_basket.price * item_basket.count;
      });
      this.$myState.countBasketItems = this.$myState.savedBasketItems.length;
      ElNotification({
        title: "اضافه به سبد خرید",
        dangerouslyUseHTMLString: true,
        message:
          "<p style='direction:rtl;'>آیتم  " +
          newItem.title +
          " با موفقیت به سبد خرید شما اضافه شد.</p>",
        type: "success",
      });
    },
    editBasketItemCount(item_info) {
      debugger;
      if (
        this.$myState.savedBasketItems.filter((x) => x.id == item_info.id)
          .length > 0
      ) {
        if (
          this.$myState.savedBasketItems.filter((x) => x.id == item_info.id)[0]
            .count > 1
        ) {
          this.$myState.savedBasketItems.filter(
            (x) => x.id == item_info.id
          )[0] = item_info;
          // this.$myState.totalBasketAmount =
          //   this.$myState.savedBasketItems.reduce(
          //     (holder_data, item_basket) => {
          //       debugger;
          //       console.log("editBasketItemCount ", item_basket);
          //       return holder_data + item_basket.price * item_basket.count;
          //     },
          //     0
          //   );
        } else {
          this.decreaseItemCount(item_info);
        }
      }
    },
    async handleDelete(rowData) {
      debugger;
      this.$myState.savedBasketItems = this.$myState.savedBasketItems.filter(
        (x) => x.id != rowData.id
      );

      this.$myState.totalBasketAmount = 0;
      this.$myState.savedBasketItems.forEach((item_basket) => {
        this.$myState.totalBasketAmount +=
          item_basket.price * item_basket.count;
      });
      this.$myState.countBasketItems = this.$myState.savedBasketItems.length;

      ElNotification({
        title: "حذف",
        dangerouslyUseHTMLString: true,
        message:
          "<p style='direction:rtl;'>آیتم  " +
          rowData.p_title +
          " با موفقیت از سبد خرید شما حذف شد.</p>",
        type: "warning",
      });
      return 1;
    },
    increaseItemCount(newItem) {
      debugger;
      if (
        this.$myState.savedBasketItems.filter((x) => x.id == newItem.id)
          .length > 0
      ) {
        this.$myState.savedBasketItems.filter((x) => x.id == newItem.id)[0]
          .count++;
      }

      this.$myState.totalBasketAmount = 0;
      this.$myState.savedBasketItems.forEach((item_basket) => {
        this.$myState.totalBasketAmount +=
          item_basket.price * item_basket.count;
      });
      this.$myState.countBasketItems = this.$myState.savedBasketItems.length;
      if(newItem.title != undefined)
      {
        ElNotification({
          title: "اضافه به سبد خرید",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>آیتم  " +
            newItem.title +
            " با موفقیت به سبد خرید شما اضافه شد.</p>",
          type: "success",
        });
      }
      else 
      {
        ElNotification({
          title: "اضافه به سبد خرید",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>آیتم  " +
            newItem.p_title +
            " با موفقیت به سبد خرید شما اضافه شد.</p>",
          type: "success",
        });
      }
     
    },
    decreaseItemCount(newItem) {
      debugger;
      if (
        this.$myState.savedBasketItems.filter((x) => x.id == newItem.id)
          .length > 0
      ) {
        if (
          this.$myState.savedBasketItems.filter((x) => x.id == newItem.id)[0]
            .count > 1
        ) {
          this.$myState.savedBasketItems.filter((x) => x.id == newItem.id)[0]
            .count--;
        } else {
          this.$myState.savedBasketItems =
            this.$myState.savedBasketItems.filter((x) => x.id != newItem.id);
        }
      }

      this.$myState.totalBasketAmount = 0;
      this.$myState.savedBasketItems.forEach((item_basket) => {
        this.$myState.totalBasketAmount +=
          item_basket.price * item_basket.count;
      });
      this.$myState.countBasketItems = this.$myState.savedBasketItems.length;
      if (newItem.title != undefined) {
        ElNotification({
          title: "",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>آیتم  " +
            newItem.title +
            " با موفقیت از سبد خرید شما کم شد.</p>",
          type: "success",
        });
      } else {
        ElNotification({
          title: "",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>آیتم  " +
            newItem.p_title +
            " با موفقیت از سبد خرید شما کم شد.</p>",
          type: "success",
        });
      }
    },
  },
};
