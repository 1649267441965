<template>
  <div class="about">
    <h1>ثبت اخبار جدید</h1>
    <div class="contact-row">
      <div class="contact-img mob-hide">
        <img src="@/assets/img/icons/newspaper.png" alt="ContactImage" />
      </div>
      <div class="contact-card mob-w-40">
        <el-card>
          <el-form :model="form" label-width="120px">
            <el-form-item class="lbl-info" label="عنوان">
              <el-input class="input-info" v-model="form.title" maxlength="150" show-word-limit />
            </el-form-item>
            <el-form-item class="lbl-info" label="توضیحات">
              <el-input class="input-info" v-model="form.body" type="textarea" maxlength="1500" show-word-limit />
            </el-form-item>
            <el-form-item class="lbl-info" label="تاریخ ثبت">
              <DatePicker input-class="date-center" v-model="form.dateNews"></DatePicker>
            </el-form-item>
            <el-form-item class="lbl-info" label="دسته بندی">
              <el-select class="input-info" v-model="form.category" placeholder="دسته بندی مورد نظر را انتخاب نمایید">
                <el-option v-for="(item_type, key) in savedCategories" :key="key" :label="item_type.title"
                  :value="item_type.value" />
              </el-select>
            </el-form-item>
            <el-form-item class="lbl-info" label="وضعیت نمایش">
              <el-select class="input-info" v-model="form.status" placeholder="وضعیت نمایش خبر را انتخاب نمایید">
                <el-option v-for="(item_type, key) in savedStatus" :key="key" :label="item_type.title"
                  :value="item_type.value" />
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="Activity time">
              <el-col :span="11">
                <el-date-picker v-model="form.date1" type="date" placeholder="Pick a date" style="width: 100%" />
              </el-col>
              <el-col :span="2" class="text-center">
                <span class="text-gray-500">-</span>
              </el-col>
              <el-col :span="11">
                <el-time-picker v-model="form.date2" placeholder="Pick a time" style="width: 100%" />
              </el-col>
            </el-form-item> -->
            <!-- <el-form-item class="lbl-info" label="عضویت در خبر نامه">
              <el-switch class="input-info" v-model="form.delivery" />
            </el-form-item> -->
            <!-- <el-form-item label="Activity type">
              <el-checkbox-group v-model="form.type">
                <el-checkbox label="Online activities" name="type" />
                <el-checkbox label="Promotion activities" name="type" />
                <el-checkbox label="Offline activities" name="type" />
                <el-checkbox label="Simple brand exposure" name="type" />
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="Resources">
              <el-radio-group v-model="form.resource">
                <el-radio label="Sponsor" />
                <el-radio label="Venue" />
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item class="lbl-info" label="متن پیغام : ">
              <el-input  class="input-info" v-model="form.desc" type="textarea" />
            </el-form-item> -->
            <el-form-item>
              <el-button v-if="editMode" type="primary" @click="editSavedNews" class="btn-send">اصلاح</el-button>
              <el-button v-else type="primary" @click="saveNewNes" class="btn-send">ثبت</el-button>
              <el-button class="btn-back" @click="gotoSavedNews">بازگشت</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

  </div>
</template>
<script>
import { ElNotification } from "element-plus";

export default {
  components: { ElNotification },
  created() {
    if (
      this.$route.params.NwsId != undefined &&
      this.$route.params.NwsId != ""
    ) {
      this.getSavedNewsInfo();
    }
  },
  data() {
    return {
      editMode: false,
      savedCategories: [
        {
          id: 10,
          title: 'عمومی',
          value: 'public'
        },
        {
          id: 11,
          title: 'برنامه نویسی',
          value: 'programming'
        },
        {
          id: 12,
          title: 'تکنولوژی',
          value: 'tech'
        },
      ],
      savedStatus: [
        {
          id: 10,
          title: 'نمایش',
          value: 1
        },
        {
          id: 11,
          title: 'عدم نمایش',
          value: 0
        },
        {
          id: 12,
          title: 'تعلیق',
          value: -1
        },
      ],

      form: {
        status: 1,
        category: 'public'
      },
    }
  },
  methods: {
    editSavedNews() {
      let dataHolder = {
        Id : this.$route.params.NwsId,
        NewsTitle: this.form.title,
        NewsBody: this.form.body,
        NewsDate: this.form.dateNews,
        NewsStatus: this.form.status,
        NewsCategory: this.form.category,

      }
      this.$postApi({
        url: "News/EditNews",
        data: dataHolder,
        needToken: true,
      })
        .then((res) => {
          debugger;
          if (res.status == 200) {
            ElNotification({
              title: "عملیات موفقیت آمیز",
              dangerouslyUseHTMLString: true,
              message: "<p style='direction:rtl;'>اخبار جدید  " + '' + " با موفقیت اصلاح گردید.</p>",
              type: "success",
            });
          }
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    getSavedNewsInfo() {
      this.editMode = true;
      this.$postApi({
        url: "News/GetSingleNewsById?idSent=" + this.$route.params.NwsId,
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          let category_temp = this.savedCategories.filter(
            (x) =>
              x.value.toLowerCase() == res.allData[0].newsCategory.toLowerCase()
          )[0];
          let type_temp = this.savedStatus.filter(
            (x) => x.value == res.allData[0].newsStatus
          )[0];
          debugger;
          this.form = {
            title: res.allData[0].newsTitle,
            body: res.allData[0].newsBody,
            category: category_temp.value,
            status: type_temp.value,
            dateNews: res.allData[0].newsDate,
          };
          debugger;
          // let imageHolderTemp = res.allData[0].fileNames.split("~");
          // this.savedImagesTotal = res.allData[0].fileNames;
          // let self = this;
          // console.log(this.img_url);
          // // this.savedImages = [];
          // imageHolderTemp.forEach((element) => {
          //   debugger;
          //   if (element != "") {
          //     this.savedImages.push({
          //       file_name: element,
          //       // file_name: self.img_url + element,
          //     });
          //   }
          // });
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    saveNewNes() {
      let dataHolder = {

        NewsTitle: this.form.title,
        NewsBody: this.form.body,
        NewsDate: this.form.dateNews,
        NewsStatus: this.form.status,
        NewsCategory: this.form.category,

      }
      this.$postApi({
        url: "News/AddNews",
        data: dataHolder,
        needToken: true,
      })
        .then((res) => {
          debugger;
          if (res.status == 200) {
            ElNotification({
              title: "عملیات موفقیت آمیز",
              dangerouslyUseHTMLString: true,
              message: "<p style='direction:rtl;'>اخبار جدید  " + '' + " با موفقیت ثبت گردید.</p>",
              type: "success",
            });
          }
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });


    },
    gotoSavedNews() {
      this.$router.push({ name: 'SavedNews' });
    }
  },
  setup() {

  },
}
</script>
<style lang="scss">
.el-form-item__label {
  padding-left: 10px !important;
  align-items: flex-start !important;
}

.lbl-info {
  margin-left: 5px;
  margin-right: 5px;
}

.input-info {
  margin-left: 5px;
  margin-right: 5px;
}

.btn-send {
  margin-left: 10px;
  margin-right: 10px;
}

.btn-back {}

.contact-row {
  width: 85%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.contact-img {
  width: 20%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.contact-card {
  width: 40%;
  direction: rtl;
}
</style>
