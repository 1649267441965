<template>
  <div class="main-basket">
    <el-steps
      class="basket-steps"
      :space="200"
      align-center
      :active="1"
      finish-status="success"
    >
      <el-step title="نمایش آیتم های سبد خرید" @click="gotoBasketView" />
      <el-step title="زمان ارسال-تحویل" />
      <el-step title="پرداخت و دریافت" />
    </el-steps>

    <div>
      <div class="demo-collapse">
        <div class="brief-wrapper">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>تایید نهایی</span>
                <div class="header-img">
                  <img src="@/assets/img/icons/bill_2.png" />
                </div>
              </div>
            </template>
            <div class="box-basket-brief">
              <div class="brief-row-prepay">
                <div class="brief-right">تعداد موارد :</div>
                <div class="brief-left">
                  {{ $myState.countBasketItems }}
                </div>
              </div>
              <div class="brief-row-prepay">
                <div class="brief-right">مبلغ کل :</div>
                <div class="brief-left">
                  {{ $myState.totalBasketAmount }}
                </div>
              </div>
              <el-divider border-style="dashed" />
              <div class="footer-total-price">
                <el-button type="info" class="btn-total-price">
                  مبلغ کل : {{ $myState.totalBasketAmount }}
                </el-button>
              </div>
            </div>
            <template #footer>
              <div class="footer-total-price">
                <el-button
                  type="success"
                  class="btn-total-price"
                  @click="gotoPaymentInfo"
                >
                  تایید و پرداخت
                </el-button>
              </div>
            </template>
          </el-card>
        </div>
        <div class="table-wrapper" id="element-to-convert">
          <el-card>
            <div class="demo-collapse">
              <el-card class="box-card" :body-style="{ padding: '0px' }">
                <template #header>
                  <div class="card-header">
                    <span
                      ><el-icon
                        class="card-icon"
                        @click="showAddressDiv = !showAddressDiv"
                      >
                        <MapLocation /> </el-icon
                      >آدرس</span
                    >
                    <el-button
                      class="button"
                      @click="showAddressDiv = !showAddressDiv"
                      text
                    >
                      <el-icon v-if="showAddressDiv">
                        <ArrowUp />
                      </el-icon>
                      <el-icon v-else>
                        <ArrowDown />
                      </el-icon>
                    </el-button>
                  </div>
                </template>
                <div class="text item" v-if="showAddressDiv">
                  <el-radio-group
                    class="address-items"
                    v-model="selectedAddress"
                  >
                    <el-radio
                      v-for="(item_address, key) in userAddresses"
                      :key="key"
                      :label="item_address.addressText"
                      size="large"
                      border
                    >
                      {{ item_address.addressText }}
                    </el-radio>
                  </el-radio-group>
                  <div class="btn-new-address">
                    <el-button
                      type="success"
                      class="btn-total-price"
                      @click="gotoNewAddress"
                    >
                      ثبت آدرس جدید
                    </el-button>
                  </div>
                </div>
                <template #footer>
                  <div class="footer-text">
                    با توجه به سفارش شما ، نیازی به تعیین آدرس برای دریافت آیتم
                    های خرید نخواهد بود.
                  </div>
                </template>
              </el-card>

              <el-card class="box-card" :body-style="{ padding: '0px' }">
                <template #header>
                  <div class="card-header">
                    <span
                      ><el-icon
                        class="card-icon"
                        @click="showDateTimeDiv = !showDateTimeDiv"
                      >
                        <SetUp /> </el-icon
                      >زمان ارسال</span
                    >
                    <el-button
                      class="button"
                      @click="showDateTimeDiv = !showDateTimeDiv"
                      text
                    >
                      <el-icon v-if="showDateTimeDiv">
                        <ArrowUp />
                      </el-icon>
                      <el-icon v-else>
                        <ArrowDown />
                      </el-icon>
                    </el-button>
                  </div>
                </template>
                <div class="time-date-div" v-if="showDateTimeDiv">
                  <div class="date-range">
                    <el-card>
                      <div>تاریخ :</div>
                      <date-picker
                        class="date-calendar"
                        v-model="dateDeliver"
                        format="YYYY-MM-DD"
                        display-format="jYYYY-jMM-jDD"
                      />
                    </el-card>
                  </div>
                  <div class="hour-range">
                    <el-card>
                      <div>ساعت :</div>
                      <el-time-select
                        v-model="startTime"
                        :max-time="endTime"
                        class="hour-input mr-4"
                        placeholder="ساعت شروع"
                        start="08:30"
                        step="00:15"
                        end="18:30"
                      />
                      <el-time-select
                        v-model="endTime"
                        :min-time="startTime"
                        class="hour-input"
                        placeholder="ساعت پایان"
                        start="08:30"
                        step="00:15"
                        end="18:30"
                      />
                    </el-card>
                  </div>
                </div>
                <template #footer>
                  <div class="footer-text">
                    زمان دریافت آیتم های سبد خرید شما به صورت لحظه ای قابل دسترس
                    خواهد بود.
                  </div>
                </template>
              </el-card>
            </div>
          </el-card>
        </div>
        <div class="export-div">
          <!-- <el-card>
            <el-button @click="generateReport" type="info" class="btn-total-price">
              دانلود پیش فاکتور
            </el-button>
          </el-card> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import preFactorCard from "@/components/Cards/preFactorCard.vue";
import { ElNotification, ElMessage } from "element-plus";

export default {
  components: { Vue3Html2pdf, preFactorCard, ElNotification },
  data() {
    return {
      showAddressDiv: true,
      showDateTimeDiv: true,

      startTime: null,
      endTime: null,

      selectedAddress: null,

      activeName: "1",
      tabPosition: "right",
      basketTable: [],

      userAddresses: [],
    };
  },
  computed: {},
  created() {
    if (this.$needDelivery == false) {
      this.gotoPaymentInfo();
    } else {
      this.basketTable = this.$myState.savedBasketItems;
      this.getUserAddresses();
    }
  },
  methods: {
    getUserAddresses() {
      //debugger;
      if (this.$myState.userLoggedID == undefined) {
        this.$router.push({ name: "login" });
      }
      this.$postApi({
        url:
          "Address/GetAllTextAddressesByUserId?userId=" +
          this.$myState.userLoggedID,
        data: null,
        needToken: true,
      })
        .then((res) => {
          //debugger;
          this.userAddresses = res.allData;
        })
        .catch((err) => {
          //debugger;
          console.log(err);
        });
    },
    gotoNewAddress() {
      this.$router.push({ name: "AddressDetails" });
    },
    gotoBasketView() {
      this.$router.push({ name: "BasketView" });
    },
    gotoPaymentInfo() {
      this.savePreOrder();
    },
    saveOrderItems(orderIdSent) {
      //debugger;
      let itemsList = [];
      if (this.$myState.savedBasketItems.length > 0) {
        this.$myState.savedBasketItems.forEach((element) => {
          let itemsList = {
            Id: 0,
            OrderId: Number(orderIdSent),
            ProjectId: element.id,
            ProjectCount: element.count,
            ProjectTotalPrice: element.price,
            DateSaved: null,
          };

          this.$postApi({
            url: "Orders/AddNewOrderItem",
            data: itemsList,
            needToken: true,
          })
            .then((res) => {
              //debugger;
              this.$router.push({ name: "BasketPayDetails" });
              ElMessage({
                type: "ثبت اولیه",
                message: "آیتم های سفارش شما با موفقیت ثبت اولیه گردید",
              });
            })
            .catch((err) => {
              //debugger;
              console.log(err);
            });
        });

        // let dataHolder = {
        //   itemsList
        // };
        // this.$postApi({
        //   url: "Orders/AddNewOrderItem",
        //   data: itemsList,
        //   needToken: true,
        // })
        //   .then((res) => {
        //     //debugger;
        //     this.$router.push({ name: "BasketPayDetails" });
        //     ElMessage({
        //       type: "ثبت اولیه",
        //       message: "آیتم های سفارش شما با موفقیت ثبت اولیه گردید",
        //     });
        //   })
        //   .catch((err) => {
        //     //debugger;
        //     console.log(err);
        //   });
      }
    },
    savePreOrder() {
      //debugger;
      if (this.$myState.savedBasketItems.length <= 0) {
        ElMessage({
          type: "سبد خرید خالی",
          message:
            "کاربر گرامی برای ادامه عملیات ابتدا آیتم مورد نظر خود را به سبد خرید خود اضافه نمایید و  دوباره تلاش نمایید",
        });
        return;
      } else {
        if (this.$needDelivery == false) {
          let dataTimeHolder = "NotNeeded";

          let dataHolder = {
            UserId: this.$myState.userLoggedID,
            TotalItemCount: this.$myState.countBasketItems,
            TotalPrice: this.$myState.totalBasketAmount.toString(),
            OrderStatus: "inited",
            DateTimeReserve: dataTimeHolder,
          };
          this.$postApi({
            url: "Orders/AddNewOrder",
            data: dataHolder,
            needToken: true,
          })
            .then((res) => {
              //debugger;
              this.saveOrderItems(res.allData.message);

              ElMessage({
                type: "ثبت اولیه",
                message: "سفارش شما با موفقیت ثبت اولیه گردید",
              });
            })
            .catch((err) => {
              //debugger;
              console.log(err);
            });
        } else {
          if (this.selectedAddress == null) {
            ElNotification({
              title: "اطلاعات ناقص",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>آدرس تحویل کالا انتخاب نشده است ، لطفا دوباره تلاش نمایید </p>",
              type: "success",
            });
            return;
          }
          if (this.dateDeliver == undefined) {
            ElNotification({
              title: "اطلاعات ناقص",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>تاریخ تحویل کالا انتخاب نشده است ، لطفا دوباره تلاش نمایید </p>",
              type: "success",
            });
            return;
          }
          if (this.startTime == null) {
            ElNotification({
              title: "اطلاعات ناقص",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>ساعت شروع تحویل کالا انتخاب نشده است ، لطفا دوباره تلاش نمایید </p>",
              type: "success",
            });
            return;
          }
          if (this.endTime == null) {
            ElNotification({
              title: "اطلاعات ناقص",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>ساعت پایانی تحویل کالا انتخاب نشده است ، لطفا دوباره تلاش نمایید </p>",
              type: "success",
            });
            return;
          }
          let dataTimeHolder =
            this.dateDeliver + "-" + this.startTime + "/" + this.endTime;
          if (this.$myState.userLoggedID == undefined) {
            this.$router.push({ name: "login" });
          }
          let dataHolder = {
            UserId: this.$myState.userLoggedID,
            TotalItemCount: this.$myState.countBasketItems,
            TotalPrice: this.$myState.totalBasketAmount.toString(),
            OrderStatus: "inited",
            DateTimeReserve: dataTimeHolder,
          };
          this.$postApi({
            url: "Orders/AddNewOrder",
            data: dataHolder,
            needToken: true,
          })
            .then((res) => {
              //debugger;
              this.saveOrderItems(res.allData[0]);
              ElMessage({
                type: "ثبت اولیه",
                message: "سفارش شما با موفقیت ثبت اولیه گردید",
              });
            })
            .catch((err) => {
              //debugger;
              console.log(err);
            });
        }
      }
    },
    onProgress($event) {},
    startPagination() {},
    hasPaginated() {},
    beforeDownload($event) {},
    hasDownloaded($event) {},
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    handleDelete(index_sent, rowData) {
      //debugger;
      this.$myState.savedBasketItems = this.$myState.savedBasketItems.filter(
        (x) => x.id != rowData.id
      );
    },
  },
};
</script>

<style lang="scss">
.btn-new-address {
  float: right;
  margin: 10px;
}

.header-img {
  width: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.footer-text {
  text-align: right;
  font-size: 14px;
}

.el-radio__input {
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  margin-left: 6px;
}

.address-items {
  margin: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  align-items: flex-start !important;

  .el-radio {
    margin: 6px !important;
  }
}

.box-card {
  width: 100%;
  direction: rtl;
  margin: 0 auto;
  margin-bottom: 10px;
}

.time-date-div {
  display: flex;

  .date-range {
    width: 40%;
    margin: 10px;

    .date-calendar {
      margin-top: 10px;
    }
  }

  .hour-range {
    width: 40%;
    margin: 10px;

    .hour-input {
      margin: 10px;
    }
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .card-icon {
    top: 5px;
    margin-left: 5px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    padding: 5px;
    transition: transform 0.3s ease-in-out;

    &:hover {
      // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      transform: scale(1.1);
    }
  }
}

.export-div {
  width: 100%;
}

.box-basket-brief {
  width: 100%;
  direction: rtl;
  text-align: right;

  .brief-row-prepay {
    width: 100%;
    position: relative;
    display: flex;

    .brief-right {
      width: 45%;
      right: 3px;
    }

    .brief-left {
      width: 45%;
      left: 3px;
    }
  }
}

.footer-total-price {
  width: 80%;
  margin: 0 auto;

  .btn-total-price {
    width: 100%;
  }
}

// .el-table .el-table__cell {
//     padding: 8px 0;
//     min-width: 0;
//     box-sizing: border-box;
//     text-overflow: ellipsis;
//     vertical-align: middle;
//     position: relative;
//     text-align: right !important;
//     z-index: var(--el-table-index);
// }

.el-input .ops-div {
  display: flex;
}

.el-input .input-div {
  width: 60%;
}

.el-input .delete-div {
  width: 30%;
}

.el-table .basket-row {
  // --el-table-tr-bg-color: rgb(149, 45, 45);
  text-align: center !important;
}

.box-items {
  width: 100;
}

.demo-collapse {
  display: flex;
  flex-wrap: wrap;

  .el-table thead {
    text-align: right !important;

    .el-table tr {
      text-align: right !important;
      direction: rtl;
    }
  }

  .el-table th.el-table__cell {
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--el-table-header-bg-color);
    text-align: right;
    direction: rtl;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    text-align: right !important;
    direction: rtl;
  }

  .brief-wrapper {
    width: 25%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .basket-brif {
  }

  .demo-collapse {
    width: 99%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .table-wrapper {
    width: 70%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;

    .basket-table {
      direction: rtl;
    }
  }

  .el-collapse-item__header {
    width: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    height: var(--el-collapse-header-height);
    line-height: var(--el-collapse-header-height);
    background-color: var(--el-collapse-header-bg-color);
    color: var(--el-collapse-header-text-color);
    cursor: pointer;
    border-bottom: 1px solid var(--el-collapse-border-color);
    font-size: var(--el-collapse-header-font-size);
    font-weight: 500;
    transition: border-bottom-color var(--el-transition-duration);
    outline: 0;
    direction: rtl;
    flex-direction: column-reverse;
  }
}

.main-basket {
  width: 100%;

  .basket-steps {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 700px) {
  .brief-wrapper {
    width: 100% !important;
  }

  .btn-new-address {
    width: 100%;
  }

  .time-date-div {
    flex-direction: column !important;
  }

  .date-range {
    width: 93% !important;
  }

  .hour-range {
    width: 93% !important;
  }

  .table-wrapper {
    width: 100% !important;
  }
}
</style>
