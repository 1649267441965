<template>
  <div class="main-basket">
    <!-- <el-steps class="basket-steps" :space="200" align-center :active="0" finish-status="success">
      <el-step title="نمایش آیتم های سبد خرید" />
      <el-step title="دریافت پیش فاکتور شما" />
      <el-step title="پرداخت و دریافت" />
    </el-steps> -->

    <div class="header-projects">
      <el-card> سفارش های شما </el-card>
    </div>

    <div>
      <div class="demo-collapse">
        <div class="table-wrapper" id="element-to-convert">
          <el-card>
            <el-table
              v-if="savedOrders.length > 0"
              class="basket-table"
              :data="savedOrders"
              stripe
              style="width: 99%"
              row-class-name="basket-row"
            >
              <el-table-column type="index"  class="basket-col"
                prop="totalItemCount"
                label="ردیف" :index="1"  width="100"/>
              <el-table-column
                class="basket-col"
                prop="totalItemCount"
                label="تعداد کل آیتم ها"
                width="150"
              />
              <el-table-column
                class="basket-col"
                prop="totalPrice"
                label="قیمت کل"
                width="220"
              />
              <el-table-column label="وضعیت سفارش" width="220">
                <template
                  #default="scope"
                  style="
                    background: red !important;
                    display: flex;
                    justify-content: flex-end;
                    text-align: right;
                  "
                >
                  {{ scope.row.orderStatus }}
                </template>
              </el-table-column>
              <el-table-column
                class="basket-col"
                prop="dateTimeReserve"
                label="تاریخ انتخابی"
                width="200"
              />
              <el-table-column
                class="basket-col"
                prop="dateSaved"
                label="تاریخ ثبت"
                width="220"
              />
              <el-table-column
                class="basket-col"
                prop="id"
                width="150"
                label="شناسه"
              />
              <el-table-column
                class="basket-col"
                prop="userId"
                label="شناسه کاربر"
                width="150"
              />
              <el-table-column label="عملیات" width="300">
                <template #default="scope" style="display: flex">
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <div style="width: 15%; min-width: 100px; display: flex">
                      <el-button
                        style="margin-left: 5px"
                        size="small"
                        type="primary"
                        @click="handleOrderDetailsShow(scope.row)"
                        ><el-icon> <Paperclip /> </el-icon
                      ></el-button>
                      <!-- <el-button
                        size="small"
                        type="warning"
                        @click="handleEdit(scope.$index, scope.row)"
                        ><el-icon> <EditPen /> </el-icon
                      ></el-button> -->
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-else>
              <img
                src="@/assets/img/icons/box.png"
                alt="سفارشی برای شما ثبت نشده است"
              />
              <div class="no-order">سفارشی برای شما ثبت نشده است</div>
            </div>
          </el-card>
          <vue3-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="hee hee"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1000px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
          >
            <template v-slot:pdf-content>
              <!-- PDF Content Here -->

              <el-card>
                <el-table
                  class="basket-table"
                  :data="savedOrders"
                  stripe
                  style="width: 100%"
                  row-class-name="basket-row"
                >
                  <el-table-column
                    class="basket-col"
                    prop="totalItemCount"
                    label="تعداد کل آیتم ها"
                    width="150"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="totalPrice"
                    label="قیمت کل"
                    width="220"
                  />
                  <el-table-column label="وضعیت سفارش" width="220">
                    <template
                      #default="scope"
                      style="
                        background: red !important;
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                      "
                    >
                      {{ scope.row.orderStatus }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    class="basket-col"
                    prop="dateTimeReserve"
                    label="تاریخ انتخابی"
                    width="200"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="dateSaved"
                    label="تاریخ ثبت"
                    width="220"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="id"
                    width="150"
                    label="شناسه"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="userId"
                    label="شناسه کاربر"
                    width="150"
                  />
                  <el-table-column label="عملیات" width="350">
                    <template #default="scope" style="display: flex">
                      <div
                        style="
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                        "
                      >
                        <div
                          style="width: 15%; min-width: 100px; display: flex"
                        >
                          <el-button
                            style="margin-left: 5px"
                            size="small"
                            type="primary"
                            @click="handleOrderDetailsShow(scope.row)"
                            ><el-icon> <Paperclip /> </el-icon
                          ></el-button>
                          <!-- <el-button
                        size="small"
                        type="warning"
                        @click="handleEdit(scope.$index, scope.row)"
                        ><el-icon> <EditPen /> </el-icon
                      ></el-button> -->
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </template>
          </vue3-html2pdf>
        </div>
        <div class="brief-wrapper">
          <userMenu></userMenu>
          <!-- <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>منو عملیات</span>
                <div class="header-img">
                  <img src="@/assets/img/icons/list.png" />
                </div>
              </div>
            </template>
            <div class="box-basket-brief">
              <div class="brief-row-orders">
                <div class="brief-right">سفارش های شما</div>
                <div class="brief-left">
                  {{ myData.orders }}
                </div>
              </div>
              <div class="brief-row-orders">
                <div class="brief-right" @click="gotoAddresses">آدرس های شما</div>
                <div class="brief-left">
                  {{ myData.addresses }}
                </div>
              </div>
              <el-divider border-style="dashed" />
              <div class="footer-total-price">
                <el-button type="info" class="btn-total-price">
                  تعداد پروژه ها : {{ $myState.totalBasketAmount }}
                </el-button>
              </div>
            </div>
            <template #footer>
              <div class="footer-total-price">
                <el-button type="success" class="btn-total-price" @click="gotoNewProject">
                  ثبت پروژه جدید
                </el-button>
              </div>
            </template>
          </el-card> -->
        </div>
        <div class="export-div">
          <el-card>
            <el-button
              @click="generateReport"
              type="info"
              class="btn-total-price"
            >
              دانلود پیش فاکتور
            </el-button>
            <!-- <el-button @click="exportToPDF" type="info" class="btn-total-price">
               پیش فاکتور
            </el-button> -->
          </el-card>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="dialogShowOrdersDetails"
      :title="dialogTitle"
      width="600"
      :before-close="handleClose"
      modal-class="dialog-new"
    >
      <div v-if="this.savedOrderDetails <= 0">
        <h4>جزئیات خرید برای این سفارش ثبت نشده است</h4>
        <img src="@/assets/img/icons/query.png" />
      </div>
      <div v-else>
        <div v-for="(itemDetails, key) in this.savedOrderDetails" :key="key">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item
              :title="itemDetails.p_title"
              :name="itemDetails.p_title"
            >
              <el-form :model="form" label-width="120px">
                <el-form-item class="lbl-info" label="عنوان">
                  <el-input
                    v-model="itemDetails.p_title"
                    class="input-info"
                    maxlength="250"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
                <el-form-item class="lbl-info" label="توضیحات">
                  <el-input
                    v-model="itemDetails.p_body"
                    class="input-info"
                    maxlength="50"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
                <el-form-item class="lbl-info" label="نوع">
                  <el-input
                    v-model="itemDetails.typeTitle"
                    class="input-info"
                    maxlength="50"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
                <el-form-item class="lbl-info" label="دسته بندی">
                  <el-input
                    v-model="itemDetails.categoryTitle"
                    class="input-info"
                    maxlength="50"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
                <el-form-item class="lbl-info" label="قیمت">
                  <el-input
                    v-model="itemDetails.p_price"
                    class="input-info"
                    maxlength="50"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
                <el-form-item class="lbl-info" label="تاریخ">
                  <el-input
                    v-model="itemDetails.date_create"
                    class="input-info"
                    maxlength="50"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
                <el-form-item class="lbl-info" label="تعداد">
                  <el-input
                    v-model="itemDetails.projectCount"
                    class="input-info"
                    maxlength="50"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
                <el-form-item class="lbl-info" label="قیمت کل">
                  <el-input
                    v-model="itemDetails.projectTotalPrice"
                    class="input-info"
                    maxlength="50"
                    show-word-limit
                    readonly
                  />
                </el-form-item>
              </el-form>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="dialog-btn-back"
            @click="dialogShowOrdersDetails = false"
            >بازگشت</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import mixin from "@/mixins/mixin";
import msgBox from "@/mixins/msgBox";
import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import userMenu from "@/components/userPanel/userMenu";

export default {
  mixins: [mixin, msgBox],
  components: { Vue3Html2pdf, ElMessage, ElMessageBox, userMenu },
  data() {
    return {
      dialogShowOrdersDetails: false,
      savedOrders: [],
      savedOrderDetails: [],
      activeName: "1",
      tabPosition: "right",
      basketTable: [],
    };
  },
  computed: {},
  created() {
    this.getSavedOrders();
    this.basketTable = this.$myState.savedBasketItems;
  },
  methods: {
    getSavedOrders() {
      debugger;
      if (this.$myState.userLoggedID == undefined) {
        this.$router.push({ name: "login" });
      }
      this.$postApi({
        url: "Orders/GetAllOrdersByUserId?userId=" + this.$myState.userLoggedID,
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          this.savedOrders = res.allData;
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    gotoNewProject() {
      this.$router.push({ name: "ProjectDetails" });
      // this.showSimpleAlert('سبد شما خالی است', 'لطفا در صورت نیاز ابتدا آیتم های خود را انتخاب و دوباره تلاش نمایید');
    },
    onProgress($event) {},
    startPagination() {},
    hasPaginated() {},
    beforeDownload($event) {},
    hasDownloaded($event) {},
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    handleEdit(index_sent, rowData) {
      debugger;
      // this.$router.push({
      //   name: "AddressDetails",
      //   params: { AdNo: rowData.id },
      // });
    },
    handleOrderDetailsShow(rowData) {
      debugger;
      this.$postApi({
        url: "Orders/GetOrdersDetailsByUserId?orderId=" + rowData.id,
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          this.savedOrderDetails = res.allData;
          this.dialogShowOrdersDetails = true;
          ElMessage({
            type: "جزئیات خرید",
            message: "اطلاعات خرید شما با موفقیت بازیابی شد",
          });
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });

      return;
      // ElMessageBox.confirm(
      //   "آیا از حذف آیتم : " + rowData.addressTitle + " مطمئن می باشید ؟",
      //   "حذف",
      //   {
      //     confirmButtonText: "حذف",
      //     cancelButtonText: "بازگشت",
      //     type: "warning",
      //   }
      // )
      //   .then((ans) => {
      //     debugger;
      //     this.$postApi({
      //       url: "Address/DeleteSavedAddress?idSent=" + rowData.id,
      //       data: null,
      //       needToken: true,
      //     })
      //       .then((res) => {
      //         debugger;
      //         this.getSavedOrders();
      //         ElMessage({
      //           type: "حذف",
      //           message: "آدرس مورد نظر با موفقیت حذف گردید",
      //         });
      //       })
      //       .catch((err) => {
      //         debugger;
      //         console.log(err);
      //       });
      //   })
      //   .catch(() => {
      //     //   ElMessage({
      //     //     type: "info",
      //     //     message: "خطا در حذف آیتم",
      //     //   });
      //   });
    },
    // debugger;
    // this.$myState.savedBasketItems = this.$myState.savedBasketItems.filter(
    //   (x) => x.id != rowData.id
    // );
  },
};
</script>

<style lang="scss">
.el-collapse-item__header {
  direction: ltr !important;
}
.no-order {
  width: 100%;
  color: #ea4528;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1) translateY(-10px);
  }
}

.header-projects {
  margin: 10px;
}

.header-img {
  width: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.export-div {
  width: 100%;
}

.box-basket-brief {
  width: 100%;
  direction: rtl;
  text-align: right;

  .brief-row-orders {
    width: 100%;
    position: relative;
    display: flex;

    .brief-right {
      width: 45%;
      right: 3px;
    }

    .brief-left {
      width: 45%;
      left: 3px;
    }
  }
}

.footer-total-price {
  width: 80%;
  margin: 0 auto;

  .btn-total-price {
    width: 100%;
  }
}

// .el-table .el-table__cell {
//     padding: 8px 0;
//     min-width: 0;
//     box-sizing: border-box;
//     text-overflow: ellipsis;
//     vertical-align: middle;
//     position: relative;
//     text-align: right !important;
//     z-index: var(--el-table-index);
// }

.el-input .ops-div {
  display: flex;
}

.el-input .input-div {
  width: 60%;
}

.el-input .delete-div {
  width: 30%;
}

.el-table .basket-row {
  // --el-table-tr-bg-color: rgb(149, 45, 45);
  text-align: center !important;
}

.demo-collapse {
  display: flex;
  flex-wrap: wrap;

  .el-table thead {
    text-align: right !important;

    .el-table tr {
      text-align: right !important;
      direction: rtl;
    }
  }

  .el-table th.el-table__cell {
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--el-table-header-bg-color);
    text-align: right;
    direction: rtl;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    text-align: right !important;
    direction: rtl;
  }

  .brief-wrapper {
    width: 25%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .basket-brif {
  }

  .table-wrapper {
    width: 70%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;

    .basket-table {
      direction: rtl;
    }
  }

  .el-collapse-item__header {
    width: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    height: var(--el-collapse-header-height);
    line-height: var(--el-collapse-header-height);
    background-color: var(--el-collapse-header-bg-color);
    color: var(--el-collapse-header-text-color);
    cursor: pointer;
    border-bottom: 1px solid var(--el-collapse-border-color);
    font-size: var(--el-collapse-header-font-size);
    font-weight: 500;
    transition: border-bottom-color var(--el-transition-duration);
    outline: 0;
    direction: rtl;
    flex-direction: column-reverse;
  }
}

.main-basket {
  width: 100%;

  .basket-steps {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
