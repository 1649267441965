import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import mainStore from "./store/index";
import { store } from "@/store/store.js";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
import axios from 'axios'

import { postApi } from "@/plugins/axiosService"

const app = createApp(App);
app.config.globalProperties.$myState = store;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
app.config.globalProperties.$myAxios = axios;
app.config.globalProperties.$postApi = postApi;
app.config.globalProperties.$needDelivery = true;

app.component('DatePicker', Vue3PersianDatetimePicker)



// createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
app.use(mainStore);
app.use(router);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mount("#app");
