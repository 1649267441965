<template>
  <div class="about">
    <h1>ارتباط با ما</h1>
    <div class="contact-row">
      <div class="contact-img">
        <img src="@/assets/img/icons/info.png" alt="ContactImage" />
        <div>
          مدیریت سایت : آروین مقدم
        </div>
        <div>
          09104940052
        </div>
      </div>
      <div class="contact-card">
        <el-card>
          <el-form :model="form" label-width="120px">
            <el-form-item class="lbl-info-name" label="مشخصات : ">
              <el-input class="input-info" v-model="form.name" />
            </el-form-item>
            <el-form-item class="lbl-info-name" label="شماره تماس : ">
              <el-input class="input-info" v-model="form.tel" />
            </el-form-item>
            <el-form-item class="lbl-info" label="آدرس ایمیل : ">
              <el-input class="input-info" v-model="form.email" />
            </el-form-item>
            <!-- <el-form-item class="lbl-info" label="Activity zone">
              <el-select class="input-info" v-model="form.region" placeholder="please select your zone">
                <el-option label="Zone one" value="shanghai" />
                <el-option label="Zone two" value="beijing" />
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="Activity time">
              <el-col :span="11">
                <el-date-picker v-model="form.date1" type="date" placeholder="Pick a date" style="width: 100%" />
              </el-col>
              <el-col :span="2" class="text-center">
                <span class="text-gray-500">-</span>
              </el-col>
              <el-col :span="11">
                <el-time-picker v-model="form.date2" placeholder="Pick a time" style="width: 100%" />
              </el-col>
            </el-form-item> -->
            <el-form-item class="lbl-info-mailer" label="ثبت نام خبر نامه">
              <el-switch class="input-info" v-model="form.delivery" />
            </el-form-item>
            <!-- <el-form-item label="Activity type">
              <el-checkbox-group v-model="form.type">
                <el-checkbox label="Online activities" name="type" />
                <el-checkbox label="Promotion activities" name="type" />
                <el-checkbox label="Offline activities" name="type" />
                <el-checkbox label="Simple brand exposure" name="type" />
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="Resources">
              <el-radio-group v-model="form.resource">
                <el-radio label="Sponsor" />
                <el-radio label="Venue" />
              </el-radio-group>
            </el-form-item> -->
            <el-form-item class="lbl-info" label="متن پیغام : ">
              <el-input
                class="input-info"
                v-model="form.desc"
                type="textarea"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="saveContact" class="btn-send"
                >ارسال</el-button
              >
              <el-button class="btn-back" @click="goHome">بازگشت</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from "element-plus";

export default {
  components: { ElMessage },
  data() {
    return {
      form: {
        name:'',
        tel:'',
        email:'',
        delivery:true,
        desc:''
      },
    };
  },
  methods: {
    saveContact() {
      debugger;
      let dataHolder = {
        username: this.form.name,
        userTelephone: this.form.tel,
        userEmail: this.form.email,
        checkNews: this.form.delivery,
        description: this.form.desc,
      };
      this.$postApi({
        url: "Contact/AddNewContactInfo",
        data: dataHolder,
        needToken: true,
      })
        .then((res) => {
          debugger;
          if (res.status == 200) {
            ElMessage({
              type: "success",
              message:
                "پیغام شما با موفقیت در سیستم ثبت و در اسرع وقت توسط اپراتور ها پیگیری و در صورت نیاز پاسخ داده خواهد شد",
            });
          }
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
  setup() {},
};
</script>
<style scoped lang="scss">
.lbl-info-name {
  // width: 150px !important;
  margin-left: 5px;
  margin-right: 5px;
}

#lblName {
  width: 250px !important;
}

.lbl-info {
  margin-left: 5px;
  margin-right: 5px;
}

.lbl-info-mailer {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px !important;

  .el-form-item__label{
    font-size: 14px !important; 
  }
}

.input-info {
  margin-left: 5px;
  margin-right: 5px;
}

.btn-send {
  margin-left: 10px;
  margin-right: 10px;
}
.btn-back {
}
.contact-row {
  width: 85%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.contact-img {
  width: 20%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.contact-card {
  width: 40%;
  direction: rtl;
}
</style>
