import { ElMessage, ElMessageBox, rowProps } from "element-plus";
// import { Action } from 'element-plus'
import mixin from "./mixin";

export default {
  mixins: [mixin],
  components: {
    ElMessage,
    ElMessageBox,
    // ,Action
  },
  methods: {
    showSimpleAlert(title_sent, body_sent) {
      ElMessageBox.alert(body_sent, title_sent, {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: "OK",
        callback: (action) => {
          //   ElMessage({
          //     type: 'نتیجه',
          //     message: `انتخاب شما : ${action}`,
          //   })
        },
      });
    },
    showDeleteConfirm(rowData) {
      debugger;
      let holderTitle = '';
      if(rowData.p_title != undefined)
      {
        holderTitle = rowData.p_title;
      }
      else 
      {
        holderTitle = rowData.title;
      }
      ElMessageBox.confirm(
        "آیا از حذف آیتم : " + holderTitle + " مطمئن می باشید ؟",
        "حذف",
        {
          confirmButtonText: "حذف",
          cancelButtonText: "بازگشت",
          type: "warning",
        }
      )
        .then(() => {
          this.handleDelete(rowData);
          ElMessage({
            type: "success",
            message: "آیتم مورد نظر از سبد شما حذف گردید",
          });
        })
        .catch(() => {
          //   ElMessage({
          //     type: "info",
          //     message: "خطا در حذف آیتم",
          //   });
        });
    },
  },
};
