<template>
    <div class="login-main">
        <el-card :body-style="{ padding: '0px' }" class="login-div">
            <div class="login-img">
                <img src="@/assets/img/pack/application.png" class="image" />
            </div>

            <div class="login-wrapper" style="padding: 14px">
                <el-card class="box-card">
                    <span>اطلاعات کاربری خود را وارد نمایید</span>
                    <el-row class="row-user" :gutter="20">
                        <el-input v-model="userInfo" class="w-50" placeholder="0912345678" :prefix-icon="Calendar">
                            <template #append>
                                نام کاربری
                                <el-icon class="icn">
                                    <UserFilled />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-row>
                    <el-row class="row-pwd" :gutter="20">
                        <el-input v-model="userPwd" type="password" placeholder="1234" show-password
                            class="pwd-input w-50 m-2" :suffix-icon="Calendar">
                            <template #append>
                                رمز عبور
                                <el-icon class="icn">
                                    <Lock />
                                </el-icon>
                            </template>
                        </el-input>
                    </el-row>
                    <!-- <el-input v-model="userInfo" placeholder="نام کاربری" clearable class="user-input" />
                <el-input v-model="userPwd" type="password" placeholder="رمز عبور" show-password class="pwd-input" /> -->
                    <div class="btns">
                        <el-button type="primary" class="btn-enter" @click="loginUser" :loading="isLoading">ورود</el-button>
                        <el-button type="success" class="btn-back">بازگشت</el-button>
                    </div>
                    <el-link @click="gotoSignup">
                        قبلا ثبت نام نکرده اید؟ ثبت نام<el-icon class="el-icon--right"><icon-view /></el-icon>
                    </el-link>
                </el-card>

            </div>
        </el-card>
    </div>
</template>

<script>
import { ElNotification } from "element-plus";

export default {
    components: { ElNotification },
    created(){
        if(this.$myState.userLoggedInfo == true)
        {
            this.$router.push('/')
        }
    },
    data() {
        return {
            isLoading: false,
            userInfo: '',
            userPwd: '',
        }
    },
    methods: {
        loginUser() {
            debugger
            this.isLoading = true;
            let dataHolder = {
                username: this.userInfo,
                password: this.userPwd
            }

            this.$postApi({ url: 'Authenticate/login', data: dataHolder })
                .then(res => {
                    this.isLoading = false;
                    debugger
                    if (res.allData.status == '200') {
                        if (this.userInfo == 'admin') {
                            this.$myState.userRole = 'admin';
                        }
                        else {
                            this.$myState.userRole = 'user';
                        }

                        this.$myState.userIsLogged = true;
                        this.$myState.userLoggedInfo = res.allData.userInfo;
                        localStorage.setItem('uToken', res.allData.token);
                        this.$myState.userLoggedMobile = '09104940052';
                        this.$myState.userLoggedID = res.allData.userId;
                        this.$myState.userLoggedEmail = 'arvin@info.com';
                        this.$router.push({ name: 'home' });
                    }
                    else {
                        ElNotification({
                            title: "ورود ناموفق",
                            dangerouslyUseHTMLString: true,
                            message: "<p style='direction:rtl;'>اطلاعات حساب کاربری  " + this.userInfo + " در سامانه یافت نشد ، لطفا دوباره تلاش نمایید.</p>",
                            type: "warning",
                        });
                    }
                })
                .catch(err => {
                    this.isLoading = false;
                    console.log(err)
                })


        },
        gotoSignup() {
            this.$router.push({ name: 'signup' });
        }
    }
}
</script>

<style lang="scss" scoped>
.el-input-group {
    direction: ltr;
}

.login-main {
    width: 100%;
}

.login-div {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    .login-img {

        width: 150px;
        margin: 0 auto;
        margin-top: 10px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
}

.login-wrapper {
    width: 100%;
    margin: 0 auto;
    //direction: rtl;

    .icn {
        margin-left: 5px;
    }

    .row-user {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .row-pwd {}

    .user-input {
        margin: 10px;
    }

    .pwd-input {
        margin: 10px;
        width: 100%;
    }

    .btn-enter {
        margin: 5px;
    }


    .btn-back {
        margin: 5px;
    }
}
</style>