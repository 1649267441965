import axios from "@/plugins/axios";
import { store } from "@/store/store.js";

let params_with_token = null;
if (localStorage.getItem("uToken") != null) {
  params_with_token = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("uToken")}`,
    },
  };
}

import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import { h } from "vue";

export default {
  components: {
    ElMessage,
    ElMessageBox,
    // ,Action
  },
  methods: {
    showSimpleAlert(title_sent, body_sent) {
      ElMessageBox.alert(body_sent, title_sent, {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: "OK",
        callback: (action) => {
          //   ElMessage({
          //     type: 'نتیجه',
          //     message: `انتخاب شما : ${action}`,
          //   })
        },
      });
    },
  },
};

export async function postApi(postData) {
  // debugger
  // if has UPLOAD FILE
  let params_with_token = {};
  let bTokken = localStorage.getItem("uToken");
  // debugger;
  if (postData.upload) {
    // debugger;
    params_with_token.headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${bTokken}`,
    };
    params_with_token.onUploadProgress = function (progressEvent) {
      store.uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    }.bind(this);
  } else {
    // debugger;
    if (bTokken != null) {
      params_with_token = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": " application/json; charset=utf-8 ",
          "accept": '*/*',
          "Authorization": `Bearer ${bTokken}`,
        },
      };
    }
  }
  // debugger;
  // params_with_token.headers = {
  //     'Authorization': `Bearer ${store.bearerToken}`
  // }

  // //////////

  let myLoading = true;
  if (postData.loading == false) {
    myLoading = false;
  }
  store.isLoading = myLoading;

  try {
    // debugger
    const response = await axios.post(
      postData.url,
      postData.data,
      postData.needToken ? params_with_token : null
    );

    if (myLoading) {
      store.isLoading = false;
    }
    //debugger;
    if (response.data.status == "Success") {
      return {
        data: response.data.data,
        allData: response.data,
        success: true,
        status: 200,
      };
    } else {
      // this.$toast.error(response.data.message, 7000);
      // console.log("not success api response", response);

      return {
        data: response.data.data,
        allData: response.data,
        success: false,
      };
    }
  } catch (myError) {
    //debugger;
    if (myError.message == "Network Error") {
      ElNotification({
        title: "اختلال ارتباطی",
        message: h(
          "i",
          { style: "color: red;direction:rtl;text-align:right;" },
          "ارتباط با سرور در حال حاضر دچار اختلال می باشد ، لطفا دوباره تلاش نمایید"
        ),
      });
      return null;
    } else if (myError.response.statusText == "Unauthorized") {
      // this.$router.go({ name: 'login-form' });
      this.$router.push("/login");
      return null;
    }
    if (myError.message.includes("401")) {
      localStorage.setItem("isLogin", false);
      localStorage.setItem("Token", "");
      localStorage.setItem("info", "");
      localStorage.setItem("mail", "");
      localStorage.setItem("biilingUserInfo", null);
      this.$router.push("/login");
      return null;
      // this.goTo("/login")
    }

    if (myError.response.status == 401) {
      localStorage.setItem("isLogin", false);
      localStorage.setItem("Token", "");
      localStorage.setItem("info", "");
      localStorage.setItem("mail", "");
      localStorage.setItem("biilingUserInfo", null);
      this.$router.push("login");
      return null;
    }

    store.isLoading = false;
    if (myError.response.status == 403) {
      ElNotification({
        title: "محدودیت دسترسی",
        message: h(
          "i",
          { style: "color: yellow;direction:rtl;text-align:right;" },
          "کاربر گرامی ، شما امکان دسترسی به این عملیات را ندارید ، لطفا دوباره تلاش نمایید"
        ),
      });
    } else {
      ElNotification({
        title: "عملیات ناموفق",
        message: h(
          "i",
          { style: "color: red;direction:rtl;text-align:right;" },
          myError.response.data.title
        ),
      });
    }

    store.uploadPercentage = 0;
    return { success: false, error: myError };
  }
}
