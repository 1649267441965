<template>
  <div class="home">
    <sliderCarousel></sliderCarousel>
    <!-- <el-row class="mb-4">
      <el-button>پیش فرض</el-button>
      <el-button type="primary">Primary</el-button>
      <el-button type="success">Success</el-button>
      <el-button type="info">Info</el-button>
      <el-button type="warning">Warning</el-button>
      <el-button type="danger">Danger</el-button>
    </el-row> -->
    <!-- <MainHomeTabVue></MainHomeTabVue> -->
    <mainSearch @NewSearch="NewSearchOccured"></mainSearch>
    <div class="main-body">
      <div class="body-items">
        <singleCard ref="mainProjectCards"></singleCard>
      </div>
      <div class="body-side">
        <mainCategory></mainCategory>
        <filterSearch></filterSearch>
      </div>
    </div>

    <!-- <simpleCarousel></simpleCarousel> -->

  </div>
</template>

<script>
import singleCard from '@/components/Cards/singleCard.vue'
// import simpleCarousel from '@/components/Carousel/simpleCarousel.vue'
import sliderCarousel from '@/components/Carousel/sliderCarousel.vue'
import mainSearch from '@/components/search/mainSearch.vue'
import mainCategory from '@/components/catagories/mainCategory.vue'
// import MainHomeTabVue from '@/components/Tabs/MainHomeTab.vue'
import filterSearch from '@/components/catagories/filterSearch.vue'

// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    singleCard,
    // simpleCarousel,
    sliderCarousel,
    mainSearch,
    mainCategory,
    filterSearch,
    // MainHomeTabVue
  },
  methods:{
    NewSearchOccured(search_value_sent){
      debugger
      if(this.$refs.mainProjectCards != undefined)
      {
        this.$refs.mainProjectCards.getProjects(search_value_sent);
      }
    }
  }
}
</script>
<style scoped lang="scss">
.main-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .body-items {
    width: 84%;
  }

  .body-side {
    width: 13%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin-bottom: 25px;
    min-width: 210px;
  }
}

@media screen and (max-width:600px){
  
  .body-items {
    width: 99% !important;
  }

  .body-side {
    width: 99% !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin-bottom: 25px;
    min-width: 210px;
  }
}
</style>
