<template>
  <div class="main-category">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>فیلتر نمایشی</span>
          <el-button class="button" text @click="catShown = !catShown">
            <el-icon v-if="catShown">
              <ArrowUp />
            </el-icon>
            <el-icon v-else>
              <ArrowDown />
            </el-icon>
          </el-button>
        </div>
      </template>
      <div v-if="catShown" class="cat-items">
        <div v-for="(item_cat, key) in categoryItems" :key="key">
          <img class="cat-img" src="@/assets/img/icons/platform.png" :alt="item_cat.title" />
          <el-checkbox v-model="item_cat.isSelected" :label="item_cat.title" size="large"
            @change="hanldeTypeChanged(item_cat)" />
        </div>
      </div>

      <template v-if="catShown" #footer>
        <div class="footer-img-type">
          <img src="@/assets/img/icons/app.png" alt="Filter" />
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      catShown: true,
      categoryItems: [
        {
          name: "مقاله",
          isSelected: false,
        },
        {
          name: "پروژه",
          isSelected: false,
        },
        {
          name: "سورس کد",
          isSelected: false,
        },
        {
          name: "آموزش",
          isSelected: false,
        },
      ],
    };
  },
  created() {
    this.getSavedTypes();
  },
  methods: {
    getSavedTypes() {
      this.$postApi({
        url: "Types/GetAllTypes",
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          this.categoryItems = res.allData;
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    hanldeTypeChanged(evento) {
      debugger;
      let countFilterd = 0;
      this.$myState.cardItemsBase.map((elem_obj) => {
        debugger;
        elem_obj.isFiltered = false;
      });
      debugger;
      this.categoryItems.forEach((element_category) => {
        debugger;
        if (element_category.isSelected == true) {
          countFilterd++;
          console.log(element_category.title);
          this.$myState.cardItemsBase.forEach((element_item) => {
            if (element_item.type.includes(element_category.title)) {
              element_item.isFiltered = true;
            }
            // else
            // {
            //     element_item.isFiltered=false;
            // }
          });
        }
      });
      debugger;
      if (countFilterd > 0) {
        this.$myState.cardItemsFiltered = this.$myState.cardItemsBase.filter(
          (x) => x.isFiltered == true
        );
      } else {
        this.$myState.cardItemsFiltered = this.$myState.cardItemsBase;
      }
    },
  },
};
</script>

<style lang="scss">
.cat-img {
  width: 25px;
  margin-left: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.footer-img-type {
  width: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.main-category {
  width: 100%;

  .el-checkbox__input {
    margin-left: 5px;
  }
}

.cat-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  direction: rtl;
  align-content: flex-start;
  align-items: flex-start;
}
</style>
