<template>
  <div class="main-basket">
    <!-- <el-steps class="basket-steps" :space="200" align-center :active="0" finish-status="success">
      <el-step title="نمایش آیتم های سبد خرید" />
      <el-step title="دریافت پیش فاکتور شما" />
      <el-step title="پرداخت و دریافت" />
    </el-steps> -->
    <div>
      <el-card class="head-card">
        <h4>نمایش جزئیات {{ itemInfoDetails.newsTitle }}</h4>
      </el-card>
    </div>
    <div>
      <div class="demo-collapse">
        <div class="mob-basket mob-show">
          <div>
            <el-button
              type="success"
              class="btn-total-price resp"
              @click="gotoNewsPage"
            >
              بازگشت به اخبار
            </el-button>
          </div>
        </div>
        <div class="brief-wrapper mob-hide">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>عکس های مرتبط</span>
                <div class="header-img">
                  <img src="@/assets/img/icons/news.png" />
                </div>
              </div>
            </template>
            <div class="box-info-brief">
              <!-- <div class="brief-info-row">
                <div class="brief-right-info">
                  <img src="@/assets/img/icons/warning.png" />
                </div>
                <div class="brief-left-info">
                  از آخرین اخبار سایت مطبلع باشید
                </div>
              </div>
              <div class="brief-info-row">
                <div class="brief-right-info">
                  <img src="@/assets/img/icons/notes.png" />
                </div>
                <div class="brief-left-info">
                  در صورت هر گونه سوال می توانید با پشتیبانی <a>تماس</a> حاصل
                  نمایید
                </div>
              </div>
              <div class="brief-info-row">
                <div class="brief-right-info">
                  <img src="@/assets/img/icons/social.png" />
                </div>
                <div class="brief-left-info">
                  ایمیل پشتیبانی : info@agrincoder.ir
                </div>
              </div> -->
              <div class="news-img">
                <img src="@/assets/img/icons/news.png" />
              </div>
              <el-divider border-style="dashed" v-if="itemCount > 0" />
              <!-- <div class="footer-count-preivew" v-if="itemCount > 0">
                <div class="increaser-div">
                  <el-button
                    size="small"
                    type="success"
                    @click="handleIncrease()"
                    ><el-icon> <Plus /> </el-icon
                  ></el-button>
                </div>
                <div class="value-div">
                  <el-input
                    v-model="
                      $myState.savedBasketItems.filter(
                        (x) => x.id == this.$route.params.ItemNo
                      )[0].count
                    "
                    type="number"
                    min="1"
                    max="99"
                    placeholder="تعداد"
                  />
                </div>
                <div class="decreaser-div">
                  <el-button
                    v-if="itemCount == 1"
                    size="small"
                    type="danger"
                    @click="handleDeletePre()"
                    ><el-icon> <Delete /> </el-icon
                  ></el-button>
                  <el-button
                    v-else
                    size="small"
                    type="warning"
                    @click="handleDecrease()"
                    ><el-icon> <Minus /> </el-icon
                  ></el-button>
                </div>
              </div> -->
            </div>
            <template #footer>
              <div class="footer-count-preivew">
                <el-button
                  type="success"
                  class="btn-total-price"
                  @click="gotoNewsPage"
                >
                  برگشت به اخبار
                </el-button>
              </div>
            </template>
          </el-card>
        </div>
        <div class="box-info-wrapper" id="element-to-convert">
          <div class="info-row">
            <!-- <div class="img-wrapper">
              <el-carousel
                v-if="
                  itemInfoDetails.images != undefined &&
                  itemInfoDetails.images.length > 0
                "
                :interval="0"
                type="card"
                height="300px"
                class="slide-wrapper"
                arrow="always"
              >
                <el-carousel-item
                  v-for="item in itemInfoDetails.images"
                  :key="item"
                  height="auto"
                >
                  <div class="slide-img">
                  
                    <img :src="item" @error="setErrorImage" :alt="item" />
                    {{ item }}
                  </div>
                </el-carousel-item>
              </el-carousel>
              <div class="no-image" v-else>
                <img src="@/assets/img/icons/folder.png" alt="Default Image" />
              </div>
            </div> -->
            <div class="show-body-wrapper">
              <div class="body-wrapper-card">
                <div class="line-body">
                  <div class="line-img">
                    <img src="@/assets/img/icons/tag.png" alt="Title" />
                  </div>
                  <div class="line-text">
                    عنوان خبر : <br />{{ itemInfoDetails.newsTitle }}
                  </div>
                </div>
                <el-divider
                  class="devider"
                  border-style="dashed"
                  content-position="right"
                >
                  <img src="@/assets/img/icons/arrow-down.png" alt="*" />
                </el-divider>
                <div class="line-body bot">
                  <div class="line-img">
                    <img
                      src="@/assets/img/icons/product-description.png"
                      alt="Title"
                    />
                  </div>
                  <div class="line-text">
                    توضیحات خبر : <br />{{ itemInfoDetails.newsBody }}
                  </div>
                </div>
              </div>

              <div class="cat-type-body">
                <div class="line-body">
                  <div class="line-img">
                    <img src="@/assets/img/icons/app.png" alt="Title" />
                  </div>
                  <div class="line-text drc">
                    {{ itemInfoDetails.newsCategory }} : دسته بندی
                  </div>
                </div>

                <div class="line-body">
                  <div class="line-img">
                    <img src="@/assets/img/icons/platform.png" alt="Title" />
                  </div>
                  <div class="line-text">
                    تاریخ ثبت : {{ itemInfoDetails.newsDate }}
                  </div>
                </div>
              </div>

              <div class="cat-type-body">
                <div class="line-body">
                  <div class="line-img">
                    <img src="@/assets/img/icons/calendar.png" alt="Title" />
                  </div>
                  <div class="line-text">نویسنده : ادمین</div>
                </div>

                <!-- <div class="line-body">
                  <div class="line-img">
                    <img src="@/assets/img/icons/quill.png" alt="Title" />
                  </div>
                  <div class="line-text">
                    قیمت : {{ itemInfoDetails.p_price }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="export-div">
          <el-card>
            <el-button @click="generateReport" type="info" class="btn-total-price">
              دانلود پیش فاکتور
            </el-button>
          </el-card>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import mixin from "@/mixins/mixin";
import msgBox from "@/mixins/msgBox";
import { ElNotification } from "element-plus";

export default {
  mixins: [mixin, msgBox],
  components: { Vue3Html2pdf, ElNotification },
  computed: {
    itemCount() {
      // debugger;
      if (
        this.$myState.savedBasketItems.filter(
          (x) => x.id == this.$route.params.ItemNo
        ).length > 0
      ) {
        // debugger;
        return this.$myState.savedBasketItems.filter(
          (x) => x.id == this.$route.params.ItemNo
        )[0].count;
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      // itemCount: 0,
      splitedImages: [],
      itemInfoDetails: {},
      activeName: "1",
      tabPosition: "right",
      basketTable: [],
    };
  },

  created() {
    // debugger;
    // let dataSent = this.$route.params.ItemNo;
    // this.itemInfoDetails = this.$myState.cardItemsBase.filter(
    //   (x) => x.id == dataSent
    // )[0];

    // this.basketTable = this.$myState.savedBasketItems;

    // if (
    //   this.$myState.savedBasketItems.filter(
    //     (x) => x.id == this.$route.params.ItemNo
    //   ).length > 0
    // ) {
    //   this.itemCount = this.$myState.savedBasketItems.filter(
    //     (x) => x.id == this.$route.params.ItemNo
    //   )[0].count;
    // } else {
    //   this.itemCount = 0;
    // }
    this.getNewsDetailsById();
  },
  methods: {
    setErrorImage(e) {
      e.target.src = require("@/assets/img/icons/folder.png");
    },
    getNewsDetailsById() {
      this.$postApi({
        url: "News/GetSingleNewsById?idSent=" + this.$route.params.NewsNo,
        data: null,
        needToken: false,
      })
        .then((res) => {
          this.isLoading = false;
          // debugger;
          if (res.allData.length > 0) {
            this.itemInfoDetails = res.allData[0];
            // if (this.itemInfoDetails.fileNames != "") {
            //   this.splitedImages = this.itemInfoDetails.fileNames.split("~");
            //   this.itemInfoDetails.images = [];
            //   this.splitedImages.forEach((element) => {
            //     // debugger;
            //     if (element != "") {
            //       this.itemInfoDetails.images.push(element);
            //     }
            //   });
            // }
            // this.$myState.cardItemsFiltered = res.allData;
          } else {
            ElNotification({
              title: "خطا در بازیابی اطلاعات پروژه ها",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>خطا در بازیابی اطلاعات پروژه ها ، لطفا دوباره تلاش نمایید.</p>",
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    gotoNewsPage() {
      this.$router.push({ name: "News" });
    },
    // handleDeletePre() {
    //   debugger
    //   this.itemCount = 0;
    //   this.handleDelete(this.itemInfoDetails);
    // },
    handleDecrease() {
      // debugger;
      // this.itemCount--;
      this.decreaseItemCount(this.itemInfoDetails);
    },
    handleIncrease() {
      debugger;
      // this.itemCount++;
      this.increaseItemCount(this.itemInfoDetails);
    },
    handleDeletePre() {
      this.showDeleteConfirm(this.itemInfoDetails);
      // debugger;
      // this.$myState.savedBasketItems = this.$myState.savedBasketItems.filter(
      //   (x) => x.id != rowData.id
      // );
    },
    gotoPrePay() {
      if (this.$myState.savedBasketItems.length > 0) {
        this.$router.push({ name: "BasketPrePay" });
      } else {
        this.showSimpleAlert(
          "سبد شما خالی است",
          "لطفا در صورت نیاز ابتدا آیتم های خود را انتخاب و دوباره تلاش نمایید"
        );
      }
    },
    onProgress($event) {},
    startPagination() {},
    hasPaginated() {},
    beforeDownload($event) {},
    hasDownloaded($event) {},
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss">
.mob-basket {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(175, 65, 65);
  color: white;
  text-align: center;
  z-index: 1;
  direction: rtl;
}

.slide-wrapper {
  //background: rgba(93, 93, 93, 0.039);=
  border-radius: 10px;
}

.el-carousel__item {
  background: transparent !important;
}
.slide-img {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.devider {
  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}

.head-card {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;

  h4 {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.5);
      text-shadow: -9px 5px 5px rgba(128, 0, 0, 0.54);
    }
  }
}

.news-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.header-img {
  width: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.export-div {
  width: 100%;
}

.box-info-brief {
  width: 100%;
  direction: rtl;
  text-align: right;

  .brief-info-row {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    .brief-right-info {
      width: 10%;
      right: 3px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .brief-left-info {
      width: 95%;
      left: 3px;
      margin-bottom: 10px;
    }
  }
}

.footer-count-preivew {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: center;

  .increaser-div {
    display: flex;
    align-items: center;
    margin-left: 10px;
    // width: 10%;
    // margin: 0 auto;
  }

  .value-div {
    display: flex;
    align-items: center;
    // width:15%;
    // margin: 0 auto;
  }

  .decreaser-div {
    display: flex;
    align-items: center;
    margin-right: 10px;
    // width: 10%;
    // margin: 0 auto;
  }

  .btn-total-price {
    width: 100%;
  }

  .resp {
    margin: 10px;
  }
}

// .el-table .el-table__cell {
//     padding: 8px 0;
//     min-width: 0;
//     box-sizing: border-box;
//     text-overflow: ellipsis;
//     vertical-align: middle;
//     position: relative;
//     text-align: right !important;
//     z-index: var(--el-table-index);
// }

.el-input .ops-div {
  display: flex;
}

.el-input .input-div {
  width: 60%;
}

.el-input .delete-div {
  width: 30%;
}

.el-table .basket-row {
  // --el-table-tr-bg-color: rgb(149, 45, 45);
  text-align: center !important;
}

.demo-collapse {
  display: flex;
  flex-wrap: wrap;

  .el-table thead {
    text-align: right !important;

    .el-table tr {
      text-align: right !important;
      direction: rtl;
    }
  }

  .el-table th.el-table__cell {
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--el-table-header-bg-color);
    text-align: right;
    direction: rtl;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    text-align: right !important;
    direction: rtl;
  }

  .brief-wrapper {
    width: 25%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .basket-brif {
  }

  .box-info-wrapper {
    width: 70%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    display: flex;

    .info-row {
      width: 100%;
      display: flex;
      box-shadow: rgba(114, 114, 114, 0.24) 0px 3px 8px;
    }

    .no-image {
      width: 65%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .img-wrapper {
      width: 39%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin: 25px;
    }

    .show-body-wrapper {
      width: 99%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .body-wrapper-card {
        width: 98%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;
        padding-right: 10px;
        padding-top: 10px;
        margin-top: 20px;
      }

      .line-body {
        display: flex;
        align-items: center;
        width: 100%;
        direction: rtl;
        margin-top: 10px;

        &.bot {
          margin-bottom: 50px;
        }

        .line-img {
          width: 40px;
          margin-left: 10px;
          margin-right: 8px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .line-text {
          width: 88%;
          text-align: right;

          &.drc {
            direction: ltr;
          }
        }
      }

      .cat-type-body {
        display: flex;
        align-items: center;
        width: 98.4%;
        direction: rtl;
        margin-top: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;

        .cat-type-img {
          width: 40px;
          margin-left: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .cat-type-text {
          width: 88%;
          text-align: right;
        }
      }
    }

    .basket-table {
      direction: rtl;
    }
  }

  .el-collapse-item__header {
    width: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    height: var(--el-collapse-header-height);
    line-height: var(--el-collapse-header-height);
    background-color: var(--el-collapse-header-bg-color);
    color: var(--el-collapse-header-text-color);
    cursor: pointer;
    border-bottom: 1px solid var(--el-collapse-border-color);
    font-size: var(--el-collapse-header-font-size);
    font-weight: 500;
    transition: border-bottom-color var(--el-transition-duration);
    outline: 0;
    direction: rtl;
    flex-direction: column-reverse;
  }
}

.main-basket {
  width: 100%;

  .basket-steps {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 700px) {
  .box-info-wrapper {
    width: 96% !important;
  }

  .info-row {
    display: inline !important;
  }

  .show-body-wrapper {
    width: 100% !important;
  }

  .img-wrapper {
    width: 99% !important;
  }

  .no-image {
    max-width: 99% !important;
  }
}
</style>
