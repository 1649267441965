import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import loginForm from '../views/loginForm.vue'
import signupForm from '../views/signupForm.vue'
import BasketView from '../views/BasketView'
import BasketPrePay from '../views/BasketPrePay'
import BasketPayDetails from '../views/BasketPayDetails'
import ContactUs from '../views/ContactUs'
import ItemDetails from '../views/ItemDetails'

// Admin Pages
import ProjectDetails from '../views/Admin/ProjectDetails'
import SavedProjects from '../views/Admin/SavedProjects'
import NewsDetails from '../views/Admin/NewsDetails'
import SavedNews from '../views/Admin/SavedNews'
import SavedContact from '../views/Admin/SavedContact'

// User Pages
import AddressDetails from '../views/User/AddressDetails'
import SavedAddresses from '../views/User/SavedAddresses'
import userPanel from '../views/User/userPanel'
import SavedOrders from '../views/User/SavedOrders'



// NewsPages
import News from '../views/News.vue'
import NewsDescription from '../views/NewsDescription'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/login',
    name:'login',
    component:loginForm
  },
  {
    path:'/signup',
    name:'signup',
    component:signupForm
  },
  {
    path:'/BasketView',
    name:'BasketView',
    component:BasketView
  },
  {
    path:'/BasketPrePay',
    name:'BasketPrePay',
    component:BasketPrePay
  },
  {
    path:'/BasketPayDetails',
    name:'BasketPayDetails',
    component:BasketPayDetails
  },
  {
    path:'/ItemDetails/:ItemNo?',
    name:'ItemDetails',
    component:ItemDetails,
    props:true
  },
  {
    path:'/ItemDetailsFree/:ItemNo?',
    name:'ItemDetailsFree',
    component:() => import('@/views/ItemDetailsFree.vue'),
    props:true
  },
  {
    path:'/ContactUs',
    name:'ContactUs',
    component:ContactUs
  },
  // admin
  {
    path:'/ProjectDetails/:PrId?',
    name:'ProjectDetails',
    component:ProjectDetails
  },
  {
    path:'/SavedProjects',
    name:'SavedProjects',
    component:SavedProjects
  },
  {
    path:'/SavedContact',
    name:'SavedContact',
    component:  SavedContact

  },
  {
    path:'/NewsDetails/:NwsId?',
    name:'NewsDetails',
    component:NewsDetails
  },
  {
    path:'/SavedNews',
    name:'SavedNews',
    component:SavedNews
  },
  {
    path:'/AddressDetails/:AdNo?',
    name:'AddressDetails',
    component:AddressDetails
  },
  {
    path:'/SavedAddresses',
    name:'SavedAddresses',
    component:SavedAddresses
  },
  {
    path:'/userPanel',
    name:'userPanel',
    component:userPanel
  },
  {
    path:'/SavedOrders',
    name:'SavedOrders',
    component:SavedOrders
  },
  {
    path:'/News/:Cat?',
    name:'News',
    component:News
  },
  {
    path:'/NewsDescription/:NewsNo?',
    name:'NewsDescription',
    component:NewsDescription,
    props:true
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = 'سیاره برنامه نویسی';
  next();
});

export default router
