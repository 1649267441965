<template>
  <div class="about">
    <h1>ثبت پروژه جدید</h1>
    <div class="contact-row">
      <div class="contact-img mob-hide">
        <img src="@/assets/img/icons/project.png" alt="ContactImage" />
      </div>
      <div class="contact-card mob-w-40">
        <el-card>
          <el-form :model="form" label-width="120px">
            <el-form-item class="lbl-info" label="عنوان">
              <el-input
                class="input-info"
                v-model="form.title"
                maxlength="150"
                show-word-limit
              />
            </el-form-item>
            <el-form-item class="lbl-info" label="توضیحات">
              <el-input
                class="input-info"
                v-model="form.body"
                type="textarea"
                maxlength="1500"
                show-word-limit
              />
            </el-form-item>
            <el-form-item class="lbl-info" label="قیمت">
              <el-input
                class="input-info"
                v-model="form.price"
                maxlength="150"
                show-word-limit
              />
            </el-form-item>
            <el-form-item class="lbl-info" label="دسته بندی">
              <div class="dv-info-flx">
                <div class="input-info-flx">
                  <el-select
                    class="input-info"
                    v-model="form.category"
                    placeholder="دسته بندی مورد نظر را انتخاب نمایید"
                  >
                    <el-option
                      v-for="(item_type, key) in savedCategories"
                      :key="key"
                      :label="item_type.title"
                      :value="item_type.value"
                    />
                  </el-select>
                </div>
                <div class="btn-info-flx">
                  <el-button
                    type="primary"
                    @click="showNewCategory"
                    class="btn-send"
                    circle
                  >
                    <el-icon>
                      <Plus />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="lbl-info" label="نوع">
              <div class="dv-info-flx">
                <div class="input-info-flx">
                  <el-select
                    class="input-info-flx"
                    v-model="form.type"
                    placeholder="نوغ آیتم خود را انتخاب نمایید"
                  >
                    <el-option
                      v-for="(item_type, key) in savedTypes"
                      :key="key"
                      :label="item_type.title"
                      :value="item_type.value"
                    />
                  </el-select>
                </div>
                <div class="btn-info-flx">
                  <el-button
                    type="primary"
                    @click="showNewType()"
                    class="btn-send"
                    circle
                  >
                    <el-icon>
                      <Plus />
                    </el-icon>
                  </el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="lbl-info" label="تاریخ ثبت">
              <DatePicker
                input-class="date-center"
                v-model="form.dateProject"
              ></DatePicker>
            </el-form-item>
            <el-form-item class="lbl-info-porject" label="فایل پروژه">
              <input
                class="prj-input"
                type="file"
                ref="fileProject"
                accept="*/*"
                @change="uploadProjectFile"
              />
              <el-button
                v-if="editMode"
                type="primary"
                @click="downProject()"
                class="btn-download-project"
                >دانلود فایل پروژه</el-button
              >
            </el-form-item>
            <el-form-item class="lbl-info" label="نمونه عکس ها">
              <input
                class="pic-input"
                type="file"
                ref="fileInput"
                accept="image/jpeg"
                @change="uploadImage"
                multiple
              />
            </el-form-item>
            <el-form-item>
              <!-- <el-upload
                ref="uploadRef"
                v-model:file-list="fileList"
                action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                :on-change="handleChange"
                :auto-upload="false"
              >
                <el-icon>
                  <Plus />
                </el-icon>
              </el-upload> -->
              <div class="img-carousel-wrapper">
                <el-carousel
                  :interval="4000"
                  type="card"
                  height="200px"
                  class="img-carousel"
                >
                  <el-carousel-item
                    v-for="item_file in savedImages"
                    :key="item_file"
                  >
                    <div class="img-carousel-div">
                      <div class="img-carousel-delete">
                        <img
                          src="@/assets/img/icons/trash.png"
                          alt="Delete"
                          @click="setDeleteImage(item_file)"
                        />
                      </div>
                      <img
                        class="img-proj"
                        :src="imgBaseUrl + item_file.file_name"
                        @error="setErrorImage"
                        :alt="item"
                      />
                      <!-- <h3
                        text="2xl"
                        justify="center"
                        @click="showImgDetails(item_file)"
                      >
                        {{ img_url }}{{ item_file.file_name }}
                      </h3> -->
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>

              <!-- <el-dialog v-model="dialogVisible">
                <img w-full :src="dialogImageUrl" alt="Preview Image" />
              </el-dialog> -->
            </el-form-item>
            <!-- <el-form-item label="Activity time">
              <el-col :span="11">
                <el-date-picker v-model="form.date1" type="date" placeholder="Pick a date" style="width: 100%" />
              </el-col>
              <el-col :span="2" class="text-center">
                <span class="text-gray-500">-</span>
              </el-col>
              <el-col :span="11">
                <el-time-picker v-model="form.date2" placeholder="Pick a time" style="width: 100%" />
              </el-col>
            </el-form-item> -->
            <!-- <el-form-item class="lbl-info" label="عضویت در خبر نامه">
              <el-switch class="input-info" v-model="form.delivery" />
            </el-form-item> -->
            <!-- <el-form-item label="Activity type">
              <el-checkbox-group v-model="form.type">
                <el-checkbox label="Online activities" name="type" />
                <el-checkbox label="Promotion activities" name="type" />
                <el-checkbox label="Offline activities" name="type" />
                <el-checkbox label="Simple brand exposure" name="type" />
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="Resources">
              <el-radio-group v-model="form.resource">
                <el-radio label="Sponsor" />
                <el-radio label="Venue" />
              </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item class="lbl-info" label="متن پیغام : ">
              <el-input  class="input-info" v-model="form.desc" type="textarea" />
            </el-form-item> -->
            <hr />
            <el-form-item>
              <el-button
                v-if="editMode"
                type="primary"
                @click="editSavedProject"
                class="btn-send"
                >اصلاح</el-button
              >
              <el-button
                v-else
                type="primary"
                @click="saveNewProject"
                class="btn-send"
                >ثبت</el-button
              >
              <el-button class="btn-back" @click="gotoSavedProjects"
                >بازگشت</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <el-dialog
      v-model="dialogNewCategoryType"
      :title="dialogTitle"
      width="600"
      :before-close="handleClose"
      modal-class="dialog-new"
    >
      <el-form :model="form" label-width="120px">
        <el-form-item class="lbl-info" label="عنوان">
          <el-input
            class="input-info"
            v-model="NewItem.title"
            maxlength="250"
            show-word-limit
          />
        </el-form-item>
        <el-form-item class="lbl-info" label="کد آیتم">
          <el-input
            class="input-info"
            v-model="NewItem.value"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <el-form-item class="lbl-info" label="توضیحات">
          <el-input
            class="input-info"
            v-model="NewItem.description"
            maxlength="1500"
            show-word-limit
            type="textarea"
          />
        </el-form-item>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="dialog-btn-back"
            @click="dialogNewCategoryType = false"
            >بازگشت</el-button
          >
          <el-button
            class="dialog-btn-save"
            type="primary"
            @click="saveNewIetm"
          >
            ذخیره
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import axios from "axios";
import { Plus, Delete } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

// import { UploadInstance, UploadRawFile } from 'element-plus'
export default {
  components: { ElNotification, ElMessage, ElMessageBox },
  created() {
    debugger;

    this.form.dateProject = "1402/12/18";
    this.getSavedCategories();
    this.getSavedTypes();
  },
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_IMG_URL_RAW,
      img_url: process.env.VUE_APP_IMG_URL,
      savedImages: [
        // {
        //   file_name: "1_1",
        // },
        // {
        //   file_name: "2_2",
        // },
      ],
      editMode: false,
      dialogTitle: "ثبت دسته بندی جدید",
      NewItem: {
        title: "",
        value: "",
        description: "",
      },
      dialogModeCategory: true,
      dialogNewCategoryType: false,
      selectedImage: [],
      selectedProjectFile: null,
      fileListUploading: [],
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      savedCategories: [],
      savedTypes: [],
      savedImagesTotal: "",

      form: {
        title: "1",
        body: "2",
        price: "1200",
        category: "csharp",
        type: "article",
        fileProject: null,
      },
    };
  },
  methods: {
    downProject() {
      debugger;
      window.open(process.env.VUE_APP_PRJ_URL + this.form.projectFile);
    },
    setDeleteImage(item_file) {
      debugger;
      ElMessageBox.confirm(
        "آیا از حذف آیتم : " +
          "\n" +
          item_file.file_name +
          "\n" +
          " مطمئن می باشید ؟",
        "حذف",
        {
          confirmButtonText: "حذف",
          cancelButtonText: "بازگشت",
          type: "warning",
        }
      )
        .then((ans) => {
          debugger;
          this.$postApi({
            url:
              "Projects/DeleteProjectImageByName?id=" +
              this.$route.params.PrId +
              "&file_name=" +
              item_file.file_name +
              "&totalImage=" +
              this.savedImagesTotal,
            data: null,
            needToken: true,
          })
            .then((res) => {
              debugger;
              this.savedImages = this.savedImages.filter(
                (x) => x.file_name != item_file.file_name
              );
              ElMessage({
                type: "حذف",
                message: "عکس  پروژه مورد نظر با موفقیت حذف گردید",
              });
            })
            .catch((err) => {
              debugger;
              console.log(err);
            });
        })
        .catch(() => {
          //   ElMessage({
          //     type: "info",
          //     message: "خطا در حذف آیتم",
          //   });
        });
    },
    showImgDetails(item_file) {
      console.log(`showImgDetails ${item_file}`);
    },
    getSavedProjectInfo() {
      debugger;
      this.editMode = true;
      this.$postApi({
        url: "Projects/GetSavedProjectById?id=" + this.$route.params.PrId,
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          let category_temp = this.savedCategories.filter(
            (x) =>
              x.value.toLowerCase() == res.allData[0].category.toLowerCase()
          )[0];
          let type_temp = this.savedTypes.filter(
            (x) => x.value.toLowerCase() == res.allData[0].type.toLowerCase()
          )[0];
          debugger;
          this.form = {
            title: res.allData[0].p_title,
            body: res.allData[0].p_body,
            price: res.allData[0].p_price,
            category: category_temp.value,
            type: type_temp.value,
            projectFile: res.allData[0].projectFile,
            dateProject: res.allData[0].date_project,
          };
          debugger;
          let imageHolderTemp = res.allData[0].fileNames.split("~");
          this.savedImagesTotal = res.allData[0].fileNames;
          let self = this;
          console.log(this.img_url);
          // this.savedImages = [];
          imageHolderTemp.forEach((element) => {
            debugger;
            if (element != "") {
              this.savedImages.push({
                file_name: element,
                // file_name: self.img_url + element,
              });
            }
          });
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    saveNewIetm() {
      debugger;
      let dataHolder = {
        title: this.NewItem.title,
        value: this.NewItem.value,
        description: this.NewItem.description,
      };
      if (this.dialogModeCategory == true) {
        this.$postApi({
          url: "Category/AddNewCategory",
          data: dataHolder,
          needToken: true,
        })
          .then((res) => {
            debugger;
            this.dialogNewCategoryType = false;
            ElNotification({
              title: "دسته بندی جدید",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>دسته بندی جدید  " +
                this.NewItem.title +
                " با موفقیت ثبت گردید.</p>",
              type: "success",
            });
            this.getSavedCategories();
          })
          .catch((err) => {
            debugger;
            console.log(err);
          });
      } else {
        this.$postApi({
          url: "Types/AddNewType",
          data: dataHolder,
          needToken: true,
        })
          .then((res) => {
            debugger;
            this.dialogNewCategoryType = false;
            ElNotification({
              title: "نوع جدید",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>نوع جدید  " +
                this.NewItem.title +
                " با موفقیت ثبت گردید.</p>",
              type: "success",
            });
            this.getSavedTypes();
          })
          .catch((err) => {
            debugger;
            console.log(err);
          });
      }
    },
    showNewType() {
      this.dialogTitle = "ثبت نوع جدید";
      this.dialogModeCategory = false;
      this.dialogNewCategoryType = true;
    },
    showNewCategory() {
      this.dialogTitle = "ثبت دسته بندی جدید";
      this.dialogModeCategory = true;
      this.dialogNewCategoryType = true;
    },
    getSavedCategories() {
      this.$postApi({
        url: "Category/GetAllCategoris",
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          this.savedCategories = res.allData;
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    getSavedTypes() {
      this.$postApi({
        url: "Types/GetAllTypes",
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          this.savedTypes = res.allData;
          if (
            this.$route.params.PrId != undefined &&
            this.$route.params.PrId != ""
          ) {
            this.getSavedProjectInfo();
          }
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    uploadProjectFile(e) {
      debugger;
      this.selectedProjectFile = e.target.files[0];

      if (this.editMode) {
        let bTokken = localStorage.getItem("uToken");
        if (bTokken == null) {
          ElNotification({
            title: "احراز هویت ناقض",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>خطا : برای ثبت و اصلاح عکس های پروژه باید دوباره وارد حساب کاربری خود شوید" +
              "</p>",
            type: "error",
          });
          return;
        }
        let formDataIamge = new FormData();
        formDataIamge.append("ID", this.$route.params.PrId);
        formDataIamge.append("fileImages", this.selectedProjectFile);
        axios
          .post("Projects/EditProjectFile", formDataIamge, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${bTokken}`,
            },
          })
          .then((res) => {
            ElNotification({
              title: "اصلاح فایل پروژه",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>فایل پروژه : " +
                this.selectedProjectFile.name +
                " با موفقیت اپلود شد." +
                "</p>",
              type: "success",
            });
            debugger;
          })
          .catch((err) => {
            debugger;
            ElNotification({
              title: "خطای عملیاتی",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>خطا در عملیات : " + err + "</p>",
              type: "success",
            });
          });
      }
    },
    uploadImage(e) {
      debugger;
      let imageHolder = e.target.files;
      this.selectedImage = imageHolder;

      if (this.editMode) {
        let bTokken = localStorage.getItem("uToken");
        if (bTokken == null) {
          ElNotification({
            title: "احراز هویت ناقض",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>خطا : برای ثبت و اصلاح عکس های پروژه باید دوباره وارد حساب کاربری خود شوید" +
              "</p>",
            type: "error",
          });
          return;
        }
        for (
          let imgCounter = 0;
          imgCounter < imageHolder.length;
          imgCounter++
        ) {
          let formDataIamge = new FormData();
          formDataIamge.append("ID", this.$route.params.PrId);
          formDataIamge.append("fileImages", imageHolder[imgCounter]);
          axios
            .post("Projects/AddExtraImage", formDataIamge, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${bTokken}`,
              },
            })
            .then((res) => {
              ElNotification({
                title: "اپلود عکس اضافی",
                dangerouslyUseHTMLString: true,
                message:
                  "<p style='direction:rtl;'>عکس های اضافی شماره : " +
                  imageHolder[imgCounter].name +
                  " با موفقیت اپلود شد." +
                  "</p>",
                type: "success",
              });
              debugger;
            })
            .catch((err) => {
              debugger;
              ElNotification({
                title: "خطای عملیاتی",
                dangerouslyUseHTMLString: true,
                message:
                  "<p style='direction:rtl;'>خطا در عملیات : " + err + "</p>",
                type: "success",
              });
            });
        }
      }
      // if (this.selectedImage.type.includes('jpg') || this.selectedImage.type.includes('png')) {
      //   notify('Olny Jpeg,Png files can be uploaded as icons,please try again', 'warning');
      //   return;
      // }
      // if (this.selectedImage.size > 20000) {
      //   notify('Selected icon file size can not be greather than 2 MB', 'warning');
      //   return;
      // }
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
    handleChange(uploadFile, uploadFiles) {
      debugger;
      this.fileListUploading.push(uploadFile.raw);
      this.fileList = this.fileList.slice(-3);
    },
    handlePreview(uploadFile) {
      debugger;
      console.log(uploadFile);
    },
    handleExceed(files, uploadFiles) {
      debugger;
      ElMessage.warning(
        `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${
          files.length + uploadFiles.length
        } totally`
      );
    },
    handleRemove(uploadFile, uploadFiles) {
      debugger;
      console.log(uploadFile, uploadFiles);
    },

    handlePictureCardPreview(uploadFile) {
      debugger;
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true;
    },
    editSavedProject() {
      debugger;
      let holderUpload = this.$refs.uploadRef;
      let formDataHolder = new FormData();
      let bTokken = localStorage.getItem("uToken");
      if (bTokken == null) {
        ElNotification({
          title: "احراز هویت ناقض",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>خطا : برای ثبت پروژه باید دوباره وارد حساب کاربری خود شوید" +
            "</p>",
          type: "error",
        });
        return;
      }
      console.log(this.fileList);
      //file-list
      debugger;

      // let filesCounter = this.$refs.fileInput.files;
      // if(filesCounter.length > 0 )
      // {
      //   formDataHolder.append("fileImages", filesCounter[0]);
      // }
      // else
      // {
      //   // formDataHolder.append("fileImages", null);
      // }

      let categoryId = this.savedCategories.filter(
        (x) => x.value == this.form.category
      )[0].id;
      let typeId = this.savedTypes.filter((x) => x.value == this.form.type)[0]
        .id;

      formDataHolder.append("projectId", this.$route.params.PrId);
      formDataHolder.append("projectTitle", this.form.title);
      formDataHolder.append("projectBody", this.form.body);
      formDataHolder.append("projectPrice", this.form.price);
      formDataHolder.append("projectDate", this.form.dateProject);
      formDataHolder.append("projectCategory", categoryId);
      formDataHolder.append("projectType", typeId);
      axios
        .post("Projects/EditSavedProject", formDataHolder, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bTokken}`,
          },
        })
        .then((res) => {
          debugger;
          if (res.data.status == "success") {
            ElNotification({
              title: "اصلاح اطلاعات پروژه",
              dangerouslyUseHTMLString: true,
              message:
                "<p style='direction:rtl;'>اطلاعات پروژه : " +
                this.form.title +
                " با موفقیت اصلاح شد." +
                "</p>",
              type: "success",
            });
          }
          // for (
          //   let counterLoop = 1;
          //   counterLoop < filesCounter.length;
          //   counterLoop++
          // ) {
          //   debugger;
          //   let formExtraImage = new FormData();
          //   formExtraImage.append("ID", res.data.message);
          //   formExtraImage.append("fileImages", filesCounter[counterLoop]);
          //   axios
          //     .post("Projects/AddExtraImage", formExtraImage, {
          //       headers: {
          //         "Content-Type": "multipart/form-data",
          //         Authorization: `Bearer ${bTokken}`,
          //       },
          //     })
          //     .then((res) => {
          //       ElNotification({
          //         title: "اپلود عکس اضافی",
          //         dangerouslyUseHTMLString: true,
          //         message:
          //           "<p style='direction:rtl;'>عکس های اضافی شماره : " +
          //           filesCounter[counterLoop].name +
          //           " با موفقیت اپلود شد." +
          //           "</p>",
          //         type: "success",
          //       });
          //       debugger;
          //     })
          //     .catch((err) => {
          //       debugger;
          //     });
          // }
          debugger;
        })
        .catch((err) => {
          debugger;
          ElNotification({
            title: "اصلاح پروژه",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>خطا :  " +
              err.response.data.status +
              "</p>",
            type: "error",
          });
          console.log(err);
        });
    },
    saveNewProject() {
      debugger;
      let holderUpload = this.$refs.uploadRef;
      let formDataHolder = new FormData();
      let bTokken = localStorage.getItem("uToken");
      if (bTokken == null) {
        ElNotification({
          title: "احراز هویت ناقض",
          dangerouslyUseHTMLString: true,
          message:
            "<p style='direction:rtl;'>خطا : برای ثبت پروژه باید دوباره وارد حساب کاربری خود شوید" +
            "</p>",
          type: "error",
        });
        return;
      }
      console.log(this.fileList);
      //file-list
      debugger;

      // formDataHolder.append("fileImages", this.fileList[0].raw);
      // formDataHolder.append("fileImages", this.selectedImage);

      // for (var i = 0; i < this.$refs.fileInput.files.length; i++) {
      //   debugger
      //   let file = this.$refs.fileInput.files[i];
      //   formDataHolder.append('fileImages[' + i + ']', file);
      // }

      let filesCounter = this.$refs.fileInput.files;
      let projectCounter = this.$refs.fileProject.files;
      formDataHolder.append("fileImages", filesCounter[0]);

      formDataHolder.append("fileProject", projectCounter[0]);

      // for (var i = 0; i < filesCounter.length; i++) {
      //   let fileTemp = filesCounter[i];
      //   formDataHolder.append("fileImages[" + i + "]", fileTemp);
      // }

      let categoryId = this.savedCategories.filter(
        (x) => x.value == this.form.category
      )[0].id;
      let typeId = this.savedTypes.filter((x) => x.value == this.form.type)[0]
        .id;

      // this command
      // formDataHolder.append("fileImages", this.fileListUploading);
      formDataHolder.append("projectTitle", this.form.title);
      formDataHolder.append("projectBody", this.form.body);
      formDataHolder.append("projectPrice", this.form.price);
      formDataHolder.append("projectDate", this.form.dateProject);
      formDataHolder.append("projectCategory", categoryId);
      formDataHolder.append("projectType", typeId);
      axios
        .post("Projects/AddNewProject", formDataHolder, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bTokken}`,
          },
        })
        .then((res) => {
          ElNotification({
            title: "ثبت اطلاعات اولیه",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>اطلاعات اولیه پروژه : " +
              this.form.title +
              " با موفقیت ثبت شد." +
              "</p>",
            type: "success",
          });
          debugger;
          for (
            let counterLoop = 1;
            counterLoop < filesCounter.length;
            counterLoop++
          ) {
            debugger;
            let formExtraImage = new FormData();
            formExtraImage.append("ID", res.data.message);
            formExtraImage.append("fileImages", filesCounter[counterLoop]);
            axios
              .post("Projects/AddExtraImage", formExtraImage, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${bTokken}`,
                },
              })
              .then((res) => {
                ElNotification({
                  title: "اپلود عکس اضافی",
                  dangerouslyUseHTMLString: true,
                  message:
                    "<p style='direction:rtl;'>عکس های اضافی شماره : " +
                    filesCounter[counterLoop].name +
                    " با موفقیت اپلود شد." +
                    "</p>",
                  type: "success",
                });
                debugger;
              })
              .catch((err) => {
                debugger;
              });
          }
          debugger;
        })
        .catch((err) => {
          debugger;
          ElNotification({
            title: "ثبت پروژه جدید",
            dangerouslyUseHTMLString: true,
            message:
              "<p style='direction:rtl;'>خطا :  " +
              err.response.data.status +
              "</p>",
            type: "error",
          });
          console.log(err);
        });

      // this.$postApi({
      //   url: "Projects/AddNewProject",
      //   data: formDataHolder,
      //   upload: true,
      // })
      //   .then((res) => {
      //     debugger;
      //   })
      //   .catch((err) => {
      //     debugger;
      //     console.log(err);
      //   });
    },
    gotoSavedProjects() {
      this.$router.push({ name: "SavedProjects" });
    },
  },
  setup() {},
};
</script>

<style lang="scss">
.img-carousel-div {
  position: relative;

  .img-proj {
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .img-carousel-delete {
    position: absolute;
    right: 1px;
    top: 1px;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    width: 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.img-carousel-wrapper {
  width: 100%;
}

.img-carousel {
  background: rgba(116, 147, 144, 0.605);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.dialog-btn-back {
  margin-right: 5px;
}

.dialog-btn-save {
}

.dialog-new {
  direction: rtl;
}

.pic-input {
  border: 1px solid #cccccc7b;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 94%;
}

.el-form-item__label {
  padding-left: 10px !important;
  align-items: flex-start !important;
}

.lbl-info {
  margin-left: 5px;
  margin-right: 5px;
}

.lbl-info-porject {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.prj-input {
  border: 1px solid #cccccc7b;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 73%;
}

.dv-info-flx {
  width: 100% !important;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.input-info-flx {
  width: 99% !important;
  margin-left: 5px;
  margin-right: 0px;
}

.btn-info-flx {
  width: 7% !important;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-download-project {
  margin-left: 10px;
  margin-right: 10px;
  width: 15%;
  min-width: 120px;
}

.input-info {
  margin-left: 5px;
  margin-right: 5px;
}

.btn-send {
  margin-left: 10px;
  margin-right: 10px;
}

.btn-back {
}

.contact-row {
  width: 85%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.contact-img {
  width: 20%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.contact-card {
  width: 40%;
  direction: rtl;
}
</style>
