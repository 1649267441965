<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span @click="gotoUserPanel" class="lnk">پنل کاربری</span>
          <div class="header-img">
            <img src="@/assets/img/icons/list.png" />
          </div>
        </div>
      </template>
      <div class="box-basket-brief">
        <div class="brief-row">
          <div class="brief-right lnk" @click="gotoOrders">
            <el-badge :value="myData.orders" class="item">
              <el-button>سفارش های شما</el-button>
            </el-badge>
          </div>
          <div class="brief-left">
           
          </div>
        </div>
        <div class="brief-row">
          <div class="brief-right lnk" @click="gotoAddresses">
            <el-badge :value="myData.addresses" class="item">
              <el-button>آدرس های شما</el-button>
            </el-badge>
          </div>
          <div class="brief-left">
            
          </div>
          <div class="brief-right lnk">
            <el-button
              type="primary"
              @click="gotoNewAddress"
              class="btn-total-price"
            >
              ثبت آدرس
            </el-button>
          </div>
        </div>
        <el-divider border-style="dashed" />
        <div class="footer-total-price">
          <!-- <el-button type="info" class="btn-total-price">
            تعداد پروژه ها : {{ $myState.totalBasketAmount }}
          </el-button> -->
        </div>
      </div>
      <template #footer>
        <div class="footer-total-price">
          <el-button type="success" class="btn-total-price" @click="logoutUser">
            خروج از حساب کاربری
          </el-button>
        </div>
      </template>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      myData: {
        orders: 0,
        addresses: 0,
      },
    };
  },
  created() {
    this.getAddressCount();
    this.getOrdersCount();
  },
  methods: {
    gotoOrders() {
      this.$router.push({ name: "SavedOrders" });
    },
    gotoNewAddress() {
      this.$router.push({ name: "AddressDetails" });
    },
    getOrdersCount() {
      debugger;
      if (this.$myState.userLoggedID == undefined) {
        this.$router.push({ name: "login" });
      }
      this.$postApi({
        url: "Orders/GetUserOrdersCount?userId=" + this.$myState.userLoggedID,
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          this.myData.orders = res.allData;
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    getAddressCount() {
      debugger;
      if (this.$myState.userLoggedID == undefined) {
        this.$router.push({ name: "login" });
      }
      this.$postApi({
        url: "Address/GetUserAddressCount?userId=" + this.$myState.userLoggedID,
        data: null,
        needToken: true,
      })
        .then((res) => {
          debugger;
          this.myData.addresses = res.allData;
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    },
    logoutUser() {
      this.$myState.userIsLogged = false;
      this.$myState.userLoggedInfo = null;
      this.$myState.userLoggedMobile = null;
      this.$myState.userLoggedEmail = null;
      this.$router.push({ name: "login" });
    },
    gotoAddresses() {
      this.$router.push({ name: "SavedAddresses" });
    },
    gotoUserPanel() {
      this.$router.push({ name: "userPanel" });
    },
  },
};
</script>
<style lang="scss">
.brief-row {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  margin: 10px;
  margin-right: 0px;
  width: 95% !important;
  border-radius: 5px;
  padding: 10px;
  display: flex;

  .brief-right {
    width: 45%;
    right: 3px;
  }

  .brief-left {
    width: 100px !important;
    left: 3px;
    text-align: center;
  }
}
.lnk {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin-right: 15px;

  &:hover {
    transform: scale(1.1);
  }
}
</style>
