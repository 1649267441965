<template>
  <el-card class="box-card" :body-style="{ width:'600px', padding: '0px' }">
    <template #header>
      <div class="card-header">
        <span>{{titleInfo}}</span>
        <el-button class="button" text>Operation button</el-button>
      </div>
    </template>
    <div v-for="o in 4" :key="o" class="text item">{{ "List item " + o }}</div>
    <template #footer>{{footerInfo}}</template>
  </el-card>
</template>

<script>
export default{
    props:{
        titleInfo:String,
        footerInfo:String,
    },
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>

<style scoped lang="scss">
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
  direction: rtl;
}
</style>
