<template>
    <footer class="footer-section">
        <div class="my-container">
            <div class="footer-cta">
                <div class="my-row">
                    <div class="colm">
                        <div class="single-cta">
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="cta-text">
                                <h4>آدرس</h4>
                                <span>تهران-فلکه دوم صادقیه-ساختمان آریا-31</span>
                            </div>
                        </div>
                    </div>
                    <div class="colm">
                        <div class="single-cta">
                            <i class="fas fa-phone"></i>
                            <div class="cta-text">
                                <h4>تلفن تماس</h4>
                                <span>09104940052</span>
                            </div>
                        </div>
                    </div>
                    <div class="colm">
                        <div class="single-cta">
                            <i class="far fa-envelope-open"></i>
                            <div class="cta-text">
                                <h4>آدرس ایمیل</h4>
                                <span>agrincoder@Gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-content">
                <div class="my-row">
                    <div class="colm">
                        <div class="footer-widget">
                            <div class="footer-logo" @click="gotoHome">
                                <a><img src="@/assets/logo.png" class="img-fluid" alt="logo"></a>
                            </div>
                            <div class="footer-logo">
                                <a referrerpolicy='origin' target='_blank'
                                    href='https://trustseal.enamad.ir/?id=487116&Code=GPlQ4xwQC4XRpGF4yKkbgNbOvGeGXpmW'><img
                                        referrerpolicy='origin'
                                        src='https://trustseal.enamad.ir/logo.aspx?id=487116&Code=GPlQ4xwQC4XRpGF4yKkbgNbOvGeGXpmW'
                                        alt='' style='cursor:pointer' code='GPlQ4xwQC4XRpGF4yKkbgNbOvGeGXpmW'></a>
                            </div>
                            <div class="footer-text">
                                <p>هدف تیم ما کمک به پیشرفت در حوزه های مختلف برنامه نویسی و کامپیوتری می باشد</p>
                            </div>
                            <div class="footer-social-icon">
                                <span>ما را دنبال کنید</span>
                                <a href="#"><i class="fab fa-facebook-f facebook-bg"></i></a>
                                <a href="#"><i class="fab fa-twitter twitter-bg"></i></a>
                                <a href="#"><i class="fab fa-google-plus-g google-bg"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="colm">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>لینک های مفید</h3>
                            </div>
                            <ul>
                                <li>
                                    <el-link type="warning" @click="gotoHome">صفحه اصلی</el-link>
                                </li>
                                <li> <el-link type="warning" @click="gotoHome">صفحه اصلی</el-link></li>
                                <li> <el-link type="warning">آموزش ها</el-link></li>
                                <li> <el-link type="warning">پروژه های ویژه</el-link></li>
                                <li> <el-link type="warning">اخبار</el-link></li>
                                <li> <el-link type="warning" @click="gotoAbout">درباره ما</el-link></li>
                                <li> <el-link type="warning" @click="gotoContact">تماس با ما</el-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="colm">
                        <div class="footer-widget-links">
                            <div class="footer-widget-heading">
                                <h3>عضویت در خبرنامه</h3>
                            </div>
                            <div class="footer-text-sub">
                                <p>در صورت تمایل آدرس ایمیل خود را در کادر زیر وارد نمایید :</p>
                            </div>
                            <div class="subscribe-form">
                                <form>
                                    <input class="email-sub" type="text" placeholder="آدرس ایمیل شما">
                                    <button @click="saveEmailToNews"><el-icon>
                                            <Message />
                                        </el-icon></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="my-container">
                <div class="my-row">
                    <div class="colm6">
                        <div class="copyright-text">
                            <p>تمامی حق و حقوق این سایت متعلق به این سایت می باشد &copy; <a
                                    href="https://agrincoder.ir/"></a>
                            </p>
                        </div>
                    </div>
                    <div class="colm6">
                        <div class="footer-menu">
                            <ul>
                                <li><el-link type="warning" @click="gotoContact">تماس با ما</el-link></li>
                                <li><el-link type="warning" @click="gotoAbout">درباره ما</el-link></li>
                                <li><el-link type="warning" @click="gotoHome">دانلود ها</el-link></li>
                                <li><el-link type="warning" @click="gotoHome">صفحه اصلی</el-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
    components: { ElMessage },
    methods: {
        saveEmailToNews() {
            ElMessage({
                type: "danger",
                message:
                    "در حال حاضر خبر نامه وب سایت فعال نمی باشد ، با تشکر از  شما",
            });
        },
        gotoAbout() {
            debugger
            if (this.$route.name != 'about') {
                this.$router.push({ name: "about" });
            }
        },
        gotoContact() {
            debugger
            if (this.$route.name != 'ContactUs') {
                this.$router.push({ name: "ContactUs" });
            }
        },
        gotoHome() {
            debugger
            if (this.$route.name != 'home') {
                this.$router.push({ name: "home" });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.my-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .colm {
    width: 29%;
    margin-left: 2%;
    margin-right: 2%;
    min-width: 310px;
  }
}

.footer-widget-links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.colm6 {
  width: 100%;
  min-width: 310px;
}

.my-container {
  padding-right: 15px;
  padding-left: 15px;
  // margin-right: auto;
  // margin-left: auto;
  width: 98%;
}

@media (max-width: 768px) {
  .colm {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .my-container {
    // width: 750px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

// @media (min-width: 992px) {
//     .my-container {
//         // width: 970px;
//         padding-right: 0px !important;
//         padding-left: 0px !important;
//     }
// }

// @media (min-width: 1200px) {
//     .my-my- {
//         width: 1170px;
//     }
// }

ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #151414;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
  width: 100%;
}

.single-cta {
  color: #ff5e14;
  font-size: 30px;
  float: center;
  margin-top: 8px;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
  transition: transform 0.3s ease-in-out;

  img {
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1) rotate(-25deg);
    }
  }
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-text-sub {
  width: 100%;
}

.footer-text-sub p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
  direction: rtl;
  text-align: right;
  width: 100%;
  min-width: 310px;
}

.footer-social-icon {
  display: flex;
  flex-wrap: wrap;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  width: 100%;
  min-width: 310px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3b5998;
}

.twitter-bg {
  background: #55acee;
}

.google-bg {
  background: #dd4b39;
}

.footer-widget-heading {
  width: 100%;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -15px;
  height: 2px;
  width: 100px;
  background: #ff5e14;
}

.footer-widget {
  display: inline-block;
  float: left;
  margin-bottom: 12px;
  min-width: 310px;
  width: 100%;
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #ff5e14;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
  width: 85%;
  margin: 0 auto;

  .email-sub {
    direction: ltr;
  }
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
  width: 100%;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  direction: rtl;
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
</style>
