import axios from 'axios'
// debugger
// console.log(`process.env.VUE_APP_BASE_URL : ${process.env.VUE_APP_BASE_URL}`);
const instance = axios.create({
    // baseURL: 'https://api.minista.tech/api/',
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": " application/json; charset=utf-8 ",
        "accept": '*/*',
    }
});

// instance.defaults.headers.common['Authorization'] = localStorage.getItem("uToken");

// debugger

export default instance