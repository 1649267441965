import { reactive } from "vue";
export const store = reactive({
  userIsLogged:false,
  userLoggedInfo:null,
  userLoggedMobile:null,
  userLoggedEmail:null,
  userRole: "User",
  time: "10:00",
  cardItemsBase: [
    {
      id: 101,
      title: "ماشین حساب",
      category: "C#",
      type:'پروژه',
      price: 1000,
      body: "پروژه ماشین حساب به زبان سی شارپ",
      image: "web-link.png",
      images:['application.png','eaboutus.svg','web-link.png'],
      dateSaved: "1402/01/12",
      isFiltered:false,
    },
    {
      id: 102,
      title: "خانه هوشمند",
      category: "C++",
      type:'پروژه',
      price: 1400,
      body: "پروژه خانه هوشمند به زبان سی شارپ",
      image: "application.png",
      images:['application.png','eaboutus.svg','web-link.png'],
      dateSaved: "1402/01/13",
      isFiltered:false,
    },
    {
      id: 103,
      title: "مرتب سازی دودوی",
      category: "Algorithm",
      type:'مقاله',
      price: 2100,
      body: "پروژه مرتب سازی دودوی به زبان سی شارپ",
      image: "web-link.png",
      images:['application.png','eaboutus.svg','web-link.png'],
      dateSaved: "1402/01/15",
      isFiltered:false,
    },
    {
      id: 104,
      title: "الگوریتم کافمن",
      category: "C#",
      type:'آموزش',
      price: 1300,
      body: "پروژه الگوریتم کافمن به زبان سی شارپ",
      image: "application.png",
      images:['application.png','eaboutus.svg','web-link.png'],
      dateSaved: "1402/01/19",
      isFiltered:false,
    },
    {
      id: 105,
      title: "پیاده سازی delegate ها",
      category: "C#",
      type:'آموزش',
      price: 2200,
      body: "پروژه پیاده سازی delegate ها به زبان سی شارپ",
      image: "web-link.png",
      images:['application.png','eaboutus.svg','web-link.png'],
      dateSaved: "1402/01/26",
      isFiltered:false,
    },
    {
      id: 106,
      title: "Basic Html-Css",
      category: "Html",
      type:'مقاله',
      price: 3100,
      body: "پیاده سازی وب سایت ساده با استفاده از html و css",
      image: "web-link.png",
      images:['application.png','eaboutus.svg','web-link.png'],
      dateSaved: "1402/02/03",
      isFiltered:false,
    },
    {
      id: 107,
      title: "پروژه اندرویید ساده",
      category: "Andrdoird",
      type:'پروژه',
      price: 3300,
      body: "پروژه پیاده سازی delegate ها به زبان سی شارپ",
      image: "web-link.png",
      images:['application.png','eaboutus.svg','web-link.png'],
      dateSaved: "1402/02/06",
      isFiltered:false,
    },
  ],
  bearerToken:null,
  cardItemsFiltered: [],

  savedBasketItems: [
    // {
    //   id: 101,
    //   title: "بسته آموزشی",
    //   body: "آموزش زبان برنامه نویسی c#",
    //   category: "C#",
    //   price:1200,
    //   dateTime: "1401/02/18 18:25",
    //   count:1,
    // },
    // {
    //   id: 102,
    //   title: "شبکه عصبی",
    //   body: "آموزش زبان برنامه نویسی شبکه عصبی#",
    //   category: "Algorithm",
    //   price:2000,
    //   dateTime: "1401/02/18 18:20",
    //   count:1,
    // },
    // {
    //   id: 103,
    //   title: "بسته آموزشی",
    //   body: "آموزش الگوریتم مرتب سازی درختی",
    //   category: "Algorithm",
    //   price:1400,
    //   dateTime: "1401/02/18 18:15",
    //   count:1,
    // },
  ],
  totalBasketAmount: 0,
  countBasketItems: 0,

  baseURL: process.env.VUE_APP_BASE_URL,

  isLoading: false,

 

  bearerToken:localStorage.getItem('BearerToken'),
});
