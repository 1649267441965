<template>
  <div>
    <div v-if="$myState.savedBasketItems.length > 0">
      <el-card
        class="box-card"
        v-for="(item, key) in $myState.savedBasketItems"
        :key="key"
      >
        <template #header>
          <div class="card-header">
            <el-button class="button" text>
              <el-tag class="mx-1" type="danger" effect="dark">{{
                item.category
              }}</el-tag>
            </el-button>
            <span>{{ item.title }}</span>
          </div>
        </template>
        <div class="item-body">
          {{ item.body }}
        </div>
        <el-divider content-position="right">تعداد</el-divider>
        <div class="footer-row">
          <div class="footer-count">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <el-button
                size="small"
                type="success"
                style="margin-left: 10px"
                @click="handleIncrease(item)"
                ><el-icon> <Plus /> </el-icon
              ></el-button>
              <div style="width: 20%; margin-left: 10px">
                <!-- <el-popover effect="light" trigger="hover" placement="top" width="auto">
                                                <template #default>
                                                    <div>id : {{ scope.row.title }}</div>
                                                    <div>title: {{ scope.row.body }}</div>
                                                    <div>title: {{ scope.row.category }}</div>
                                                </template>
                                                <template #reference>
                                                    <el-tag>تعداد : {{ scope.row.count }}</el-tag>
                                                </template>
                                            </el-popover> -->
                <el-input
                  v-model="item.count"
                  type="number"
                  min="0"
                  max="99"
                  placeholder="تعداد"
                />
              </div>
              <div style="width: 15%">
                <el-button
                  v-if="item.count == 1"
                  size="small"
                  type="danger"
                  @click="handleDeletePre(item)"
                  ><el-icon> <Delete /> </el-icon
                ></el-button>
                <el-button
                  v-else
                  size="small"
                  type="danger"
                  @click="handleDecrease(item)"
                  ><el-icon> <Minus /> </el-icon
                ></el-button>
              </div>
            </div>

            <!-- <el-input v-model="item.count" type="number" min="0" :change="setChangeBasketItemCount(item)"
                            max="99" placeholder="تعداد" /> -->
          </div>
          <div class="footer-price">قیمت : {{ item.price }} تومان</div>
        </div>
        <template #footer>
          <div class="footer-total-row">
            قیمت کل : {{ item.count * item.price }}
          </div>
        </template>
      </el-card>
    </div>
    <div v-else>
      <el-card class="empty-basket">
        <div>سبد خرید شما خالی است</div>
      </el-card>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/mixin";
import msgBox from "@/mixins/msgBox";

export default {
  mixins: [mixin,msgBox],
  data() {
    return {};
  },
  methods: {
    handleIncrease(rowData) {
      this.increaseItemCount(rowData);
    },
    handleDecrease(rowData) {
      this.decreaseItemCount(rowData);
    },
    handleDeletePre(rowData) {
      this.showDeleteConfirm(rowData);
    },
    setChangeBasketItemCount(item_info) {
      debugger;
      this.editBasketItemCount(item_info);
    },
  },
  created() {
    console.log(this.$myState.savedBasketItems);
  },
};
</script>

<style lang="scss" scoped>
.empty-basket {
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
  margin-bottom: 10px;

  .item-body {
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    direction: rtl;
    text-align: right;
  }
}

.footer-total-row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-row {
  display: flex;
  align-items: center;
  width: 100%;
  direction: rtl;
  flex-direction: column;
  margin: 0 auto;

  .footer-count {
    width: 100%;
  }

  .footer-price {
    width: 100%;
  }
}
</style>
