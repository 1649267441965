<template>
  <div class="main-basket">
    <el-steps
      class="basket-steps"
      :space="200"
      align-center
      :active="0"
      finish-status="success"
    >
      <el-step title="نمایش آیتم های سبد خرید" />
      <el-step title="زمان ارسال-تحویل" />
      <el-step title="پرداخت و دریافت" />
    </el-steps>

    <div>
      <div class="demo-collapse">
        <div class="brief-wrapper">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>پیش فاکتور</span>
                <div class="header-img">
                  <img src="@/assets/img/icons/shopping-cart.png" />
                </div>
              </div>
            </template>
            <div class="box-basket-brief">
              <div class="brief-row-basketview">
                <div class="brief-right">تعداد موارد :</div>
                <div class="brief-left">
                  {{ $myState.countBasketItems }}
                </div>
              </div>
              <div class="brief-row-basketview">
                <div class="brief-right">مبلغ کل :</div>
                <div class="brief-left">
                  {{ $myState.totalBasketAmount }}
                </div>
              </div>
              <el-divider border-style="dashed" />
              <div class="footer-total-price">
                <el-button type="info" class="btn-total-price">
                  مبلغ کل : {{ $myState.totalBasketAmount }}
                </el-button>
              </div>
            </div>
            <template #footer>
              <div class="footer-total-price">
                <el-button
                  type="success"
                  class="btn-total-price"
                  @click="gotoPrePay"
                >
                  تایید و ثبت سفارش
                </el-button>
                <el-button
                  type="primary"
                  class="btn-total-price"
                  @click="gotoBack"
                >
                  ادامه خرید
                </el-button>
              </div>
            </template>
          </el-card>
        </div>
        <div class="table-wrapper" id="element-to-convert">
          <el-card>
            <el-table
              v-if="$myState.savedBasketItems.length > 0"
              class="basket-table"
              :data="$myState.savedBasketItems"
              stripe
              style="width: 100%"
              row-class-name="basket-row"
            >
              <el-table-column label="عنوان" width="180">
                <template
                  #default="scope"
                  style="
                    background: red !important;
                    display: flex;
                    justify-content: flex-end;
                    text-align: right;
                  "
                >
                  {{ scope.row.title }}
                </template>
              </el-table-column>
              <el-table-column
                class="basket-col"
                prop="body"
                label="توضیحات"
                width="350"
              />
              <el-table-column
                class="basket-col"
                prop="count"
                label="تعداد"
                width="100"
              />
              <el-table-column
                class="basket-col"
                prop="price"
                label="قیمت"
                width="120"
              />
              <el-table-column
                class="basket-col"
                prop="category"
                label="دسته بندی"
                width="120"
              />
              <el-table-column
                class="basket-col"
                prop="dateTime"
                label="زمان ثبت"
              />
              <el-table-column label="عملیات" width="280">
                <template #default="scope" style="display: flex">
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <el-button
                      size="small"
                      type="success"
                      style="margin-left: 10px"
                      @click="handleIncrease(scope.$index, scope.row)"
                      ><el-icon> <Plus /> </el-icon
                    ></el-button>
                    <div style="width: 20%; margin-left: 10px">
                      <!-- <el-popover effect="light" trigger="hover" placement="top" width="auto">
                                                <template #default>
                                                    <div>id : {{ scope.row.title }}</div>
                                                    <div>title: {{ scope.row.body }}</div>
                                                    <div>title: {{ scope.row.category }}</div>
                                                </template>
                                                <template #reference>
                                                    <el-tag>تعداد : {{ scope.row.count }}</el-tag>
                                                </template>
                                            </el-popover> -->
                      <el-input
                        v-model="scope.row.count"
                        type="number"
                        min="1"
                        max="99"
                        placeholder="تعداد"
                      />
                    </div>
                    <div style="width: 15%">
                      <el-button
                        v-if="scope.row.count == 1"
                        size="small"
                        type="danger"
                        @click="handleDeletePre(scope.$index, scope.row)"
                        ><el-icon> <Delete /> </el-icon
                      ></el-button>
                      <el-button
                        v-else
                        size="small"
                        type="danger"
                        @click="handleDecrease(scope.$index, scope.row)"
                        ><el-icon> <Minus /> </el-icon
                      ></el-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-else>
              <img src="@/assets/img/icons/box.png" alt="سبد خالی" />
            </div>
          </el-card>
          <vue3-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="hee hee"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="1000px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
          >
            <template v-slot:pdf-content>
              <!-- PDF Content Here -->

              <el-card>
                <el-table
                  class="basket-table"
                  :data="$myState.savedBasketItems"
                  stripe
                  style="width: 100%"
                  row-class-name="basket-row"
                >
                  <el-table-column label="عنوان" width="180">
                    <template
                      #default="scope"
                      style="
                        background: red !important;
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                      "
                    >
                      {{ scope.row.title }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    class="basket-col"
                    prop="body"
                    label="توضیحات"
                    width="350"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="count"
                    label="تعداد"
                    width="100"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="price"
                    label="قیمت"
                    width="120"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="category"
                    label="دسته بندی"
                    width="120"
                  />
                  <el-table-column
                    class="basket-col"
                    prop="dateTime"
                    label="زمان ثبت"
                  />
                  <el-table-column label="عملیات" width="280">
                    <template #default="scope" style="display: flex">
                      <div
                        style="
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                        "
                      >
                        <div style="width: 20%; margin-left: 10px">
                          <!-- <el-popover effect="light" trigger="hover" placement="top" width="auto">
                                                <template #default>
                                                    <div>id : {{ scope.row.title }}</div>
                                                    <div>title: {{ scope.row.body }}</div>
                                                    <div>title: {{ scope.row.category }}</div>
                                                </template>
                                                <template #reference>
                                                    <el-tag>تعداد : {{ scope.row.count }}</el-tag>
                                                </template>
                                            </el-popover> -->
                          <el-input
                            v-model="scope.row.count"
                            type="number"
                            min="1"
                            max="99"
                            placeholder="تعداد"
                          />
                        </div>
                        <div style="width: 15%">
                          <el-button
                            size="small"
                            type="danger"
                            @click="handleDeletePre(scope.$index, scope.row)"
                            ><el-icon> <Delete /> </el-icon
                          ></el-button>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </template>
          </vue3-html2pdf>
        </div>
        <div class="export-div">
          <el-card>
            <el-button
              @click="generateReport"
              type="info"
              class="btn-total-price"
            >
              1دانلود پیش فاکتور
            </el-button>
            <!-- <el-button @click="exportToPDF" type="info" class="btn-total-price">
              222دانلود پیش فاکتور
            </el-button> -->
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import mixin from "@/mixins/mixin";
import msgBox from "@/mixins/msgBox";

export default {
  mixins: [mixin, msgBox],
  components: { Vue3Html2pdf },
  data() {
    return {
      activeName: "1",
      tabPosition: "right",
      basketTable: [],
    };
  },
  computed: {},
  created() {
    debugger;
    this.basketTable = this.$myState.savedBasketItems;
  },
  methods: {
    gotoBack(){
      this.$router.push({ name: "home" });
    },
    gotoPrePay() {
      if (this.$myState.savedBasketItems.length > 0) {
        this.$router.push({ name: "BasketPrePay" });
      } else {
        this.showSimpleAlert(
          "سبد شما خالی است",
          "لطفا در صورت نیاز ابتدا آیتم های خود را انتخاب و دوباره تلاش نمایید"
        );
      }
    },
    onProgress($event) {},
    startPagination() {},
    hasPaginated() {},
    beforeDownload($event) {},
    hasDownloaded($event) {},
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    handleIncrease(index_sent, rowData) {
      this.increaseItemCount(rowData);
    },
    handleDecrease(index_sent, rowData) {
      this.decreaseItemCount(rowData);
    },
    handleDeletePre(index_sent, rowData) {
      this.showDeleteConfirm(rowData);
      // debugger;
      // this.$myState.savedBasketItems = this.$myState.savedBasketItems.filter(
      //   (x) => x.id != rowData.id
      // );
    },
  },
};
</script>

<style lang="scss">
.header-img {
  width: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.export-div {
  width: 100%;
}

.box-basket-brief {
  width: 100%;
  direction: rtl;
  text-align: right;

  .brief-row-basketview {
    width: 100%;
    position: relative;
    display: flex;

    .brief-right {
      width: 45%;
      right: 3px;
    }

    .brief-left {
      width: 45%;
      left: 3px;
    }
  }
}

.footer-total-price {
  width: 80%;
  margin: 0 auto;
  display: flex;

  .btn-total-price {
    width: 100%;
    margin-left: 8px;
    margin-right: 8px;
  }
}

// .el-table .el-table__cell {
//     padding: 8px 0;
//     min-width: 0;
//     box-sizing: border-box;
//     text-overflow: ellipsis;
//     vertical-align: middle;
//     position: relative;
//     text-align: right !important;
//     z-index: var(--el-table-index);
// }

.el-input .ops-div {
  display: flex;
}

.el-input .input-div {
  width: 60%;
}

.el-input .delete-div {
  width: 30%;
}

.el-table .basket-row {
  // --el-table-tr-bg-color: rgb(149, 45, 45);
  text-align: center !important;
}

.demo-collapse {
  display: flex;
  flex-wrap: wrap;

  .el-table thead {
    text-align: right !important;

    .el-table tr {
      text-align: right !important;
      direction: rtl;
    }
  }

  .el-table th.el-table__cell {
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--el-table-header-bg-color);
    text-align: right;
    direction: rtl;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    text-align: right !important;
    direction: rtl;
  }

  .brief-wrapper {
    width: 25%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .basket-brif {
  }

  .table-wrapper {
    width: 70%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 15px;

    .basket-table {
      direction: rtl;
    }
  }

  .el-collapse-item__header {
    width: 100%;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    height: var(--el-collapse-header-height);
    line-height: var(--el-collapse-header-height);
    background-color: var(--el-collapse-header-bg-color);
    color: var(--el-collapse-header-text-color);
    cursor: pointer;
    border-bottom: 1px solid var(--el-collapse-border-color);
    font-size: var(--el-collapse-header-font-size);
    font-weight: 500;
    transition: border-bottom-color var(--el-transition-duration);
    outline: 0;
    direction: rtl;
    flex-direction: column-reverse;
  }
}

.main-basket {
  width: 100%;

  .basket-steps {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width) {
  .brief-wrapper{
    width: 100% !important;
  }

  .table-wrapper{
    width: 100% !important;
  }
}
</style>
